import classNames from 'classnames';
import { flowRight } from 'lodash';
import { isLayoutTextOnImage } from '@wix/communities-blog-client-common';
import { connect } from '../components/runtime-context';
// eslint-disable-next-line no-unused-vars
import useLayoutColorClasses from '../hooks/use-layout-color-classes';
import { getUseMobileLayoutSettings } from '../selectors/app-settings-selectors';
import { getFeedColorClassName } from '../services/layout-config';
import withHocName from './with-hoc-name';
import withIsFeedDesignEnabled from './with-is-feed-design-enabled';
import withLayoutProps from './with-layout-props';

import styles from '../styles/post-list-item.scss';

const layoutColorClasses = (getPostClassName, getFeedColorClassNameFn, layoutName) => ({
  iconColorClassName: classNames(
    getPostClassName(getFeedColorClassNameFn(layoutName, 'description-fill')),
  ),
  textColorClassName: classNames(
    getPostClassName(
      getFeedColorClassNameFn(layoutName, 'description-color'),
      getFeedColorClassNameFn(layoutName, 'description-fill'),
      'description-font',
    ),
  ),
  separatorBackgroundColorClassName: classNames(
    getPostClassName(getFeedColorClassNameFn(layoutName, 'description-background-color')),
  ),
});

const layoutColorClassesBeforeMigration = (
  layoutType,
  iconColorClassName,
  textColorClassName,
  separatorBackgroundColorClassName,
) =>
  isLayoutTextOnImage(layoutType)
    ? {
        iconColorClassName: styles.textOnImageIcons,
        textColorClassName: styles.textOnImageTextColor,
        separatorBackgroundColorClassName: styles.textOnImageSeperatorBackgroundColor,
      }
    : {
        iconColorClassName: iconColorClassName || 'blog-icon-fill',
        textColorClassName: textColorClassName || 'blog-text-color',
        separatorBackgroundColorClassName:
          separatorBackgroundColorClassName || 'blog-separator-background-color',
      };

const mapRuntimeToProps = (
  state,
  {
    applyFeedDesign,
    layoutType,
    layoutName,
    mobileLayoutName,
    getPostClassName,
    iconColorClassName,
    textColorClassName,
    separatorBackgroundColorClassName,
  },
) => {
  const currentLayoutName = getUseMobileLayoutSettings(state) ? mobileLayoutName : layoutName;
  return applyFeedDesign
    ? layoutColorClasses(getPostClassName, getFeedColorClassName, currentLayoutName)
    : layoutColorClassesBeforeMigration(
        layoutType,
        iconColorClassName,
        textColorClassName,
        separatorBackgroundColorClassName,
      );
};

/** @deprecated use {@link useLayoutColorClasses} hook instead */
const withLayoutColorClasses = flowRight(
  withLayoutProps({ fallbackEnabled: false }),
  withIsFeedDesignEnabled,
  connect(mapRuntimeToProps),
  withHocName('WithLayoutColorClasses'),
);

export default withLayoutColorClasses;
