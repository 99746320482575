import React, { type CSSProperties } from 'react';
import {
  isExperimentEnabled,
  type PostAction,
  type LayoutName,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_FEED_MORE_ACTIONS_FIX } from '@wix/communities-blog-experiments';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import { type LayoutItemConfig } from '../../services/layout-config';
import { type NormalizedPost } from '../../types';
import { useSelector } from '../runtime-context';
import { PostListItemProGalleryCategoryLabel } from './post-list-item-pro-gallery-category-label';
import { PostListItemProGalleryHeader } from './post-list-item-pro-gallery-header';
import { PostListItemProGalleryTitleDescription } from './post-list-item-pro-gallery-title-description';
import s from './post-list-item-pro-gallery-header-title-container.scss';

type Props = {
  post: NormalizedPost;
  showMoreButton: boolean;
  layoutList: boolean;
  type: LayoutName | undefined;
  styleFontSizeMetadata: CSSProperties;
  visibleActions: PostAction[] | undefined;
  withoutFooter: boolean;
  itemConfig: Partial<LayoutItemConfig>;
};

export const PostListItemProGalleryHeaderTitleContainer = ({
  post,
  showMoreButton,
  layoutList,
  type,
  styleFontSizeMetadata,
  visibleActions,
  withoutFooter,
  itemConfig,
}: Props) => {
  const feedMetadataSettings = useFeedMetadataSettings({ post });
  const isFeedMoreActionsFixEnabled = useSelector((state) =>
    isExperimentEnabled(state, EXPERIMENT_FEED_MORE_ACTIONS_FIX),
  );
  const hasIconsInHeader = showMoreButton || post?.isPinned;

  return (
    <div className={!layoutList ? s.container : undefined}>
      <PostListItemProGalleryTitleDescription
        type={type}
        post={post}
        itemConfig={itemConfig}
        showMoreButton={showMoreButton}
        withoutFooter={withoutFooter}
      />
      {!layoutList ? (
        <>
          <PostListItemProGalleryCategoryLabel post={post} type={type} />
          {((isFeedMoreActionsFixEnabled && hasIconsInHeader) ||
            feedMetadataSettings.isMetadataHeaderVisible) && (
            <PostListItemProGalleryHeader
              post={post}
              type={type}
              styleFontSizeMetadata={styleFontSizeMetadata}
              showMoreButton={showMoreButton}
              visibleActions={visibleActions}
            />
          )}
        </>
      ) : null}
    </div>
  );
};
