import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  getTPASettignsLiveSiteEditingDeprecated,
  isLayoutNameList,
  type LayoutName,
} from '@wix/communities-blog-client-common';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import useFontClassName from '../../hooks/use-font-class-name';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import usePostFontSize from '../../hooks/use-post-font-size';
import {
  getLayoutDescriptionLineCount,
  getLayoutTitleLineCount,
} from '../../selectors/app-settings-selectors';
import { getShouldUsePostListMobileTitleFontSize } from '../../selectors/mobile-settings-selectors';
import { type LayoutItemConfig } from '../../services/layout-config';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import styles from '../../styles/post-list-item.scss';
import { type NormalizedPost } from '../../types';
import PostLink from '../link/post-link';
import { useSelector } from '../runtime-context';
import { PostListItemProGalleryDescription } from './post-list-item-pro-gallery-description';
import { PostListItemProGalleryRatingsDisplay } from './post-list-item-pro-gallery-ratings-display';
import PostListItemProGalleryTitle from './post-list-item-pro-gallery-title';

type Props = {
  type: LayoutName | undefined;
  post: NormalizedPost;
  itemConfig: Partial<LayoutItemConfig>;
  showMoreButton: boolean;
  withoutFooter: boolean;
};

export const PostListItemProGalleryTitleDescription = ({
  post,
  itemConfig,
  type,
  showMoreButton,
  withoutFooter,
}: Props) => {
  const titleLineCount = useSelector((state) => getLayoutTitleLineCount({ state }));
  const descriptionLineCount = useSelector((state) => getLayoutDescriptionLineCount({ state }));
  const isLiveSiteEditingDeprecated = useSelector(getTPASettignsLiveSiteEditingDeprecated);
  const { getPostClassName, section } = useIsFeedDesignEnabled();
  const isHoverDisabled =
    isLiveSiteEditingDeprecated && section === 'my-posts' && post?.status === 'unpublished';
  const lineCount =
    itemConfig.lineCount && 'description' in itemConfig.lineCount && itemConfig.lineCount
      ? itemConfig.lineCount
      : undefined;
  const { titleFontClassName } = useFontClassName();
  const layoutList = isLayoutNameList(type);
  const feedMetadataSettings = useFeedMetadataSettings({ post });
  const withDescription = feedMetadataSettings.showPostDescription && !layoutList;
  const { postTitleFontSize, defaultPostTitleFontSize } = usePostFontSize();
  const shouldUsePostListMobileTitleFontSize = useSelector(getShouldUsePostListMobileTitleFontSize);
  const layoutTypeClassName = type ? styles[type] : undefined;

  const titleContainer = useMemo(
    () =>
      classNames(
        styles.textWrapper,
        {
          [getPostClassName('link-hashtag-hover-color').join(' ')]: !isHoverDisabled,
          [styles.fadeout]: descriptionLineCount || titleLineCount,
        },
        layoutTypeClassName,
        feedMetadataSettings.showCategoryLabel && styles.withCategoryLabel,
      ),
    [
      isHoverDisabled,
      descriptionLineCount,
      titleLineCount,
      layoutTypeClassName,
      feedMetadataSettings.showCategoryLabel,
      getPostClassName,
    ],
  );

  const titleLinkClassName = useMemo(
    () =>
      classNames(
        styles.titleWrapper,
        styles.proGallery,
        layoutTypeClassName,
        showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
      ),
    [layoutTypeClassName, showMoreButton],
  );

  const titleContainerClassName = useMemo(
    () =>
      classNames(
        styles.title,
        titleFontClassName,
        layoutTypeClassName,
        withoutFooter && styles.withoutFooter,
        !withDescription && withoutFooter && styles.withoutBottomMargin,
        !withDescription && styles.withoutDescription,
        post?.isPinned && styles.withIcons,
        showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
      ),
    [
      titleFontClassName,
      layoutTypeClassName,
      withoutFooter,
      withDescription,
      post?.isPinned,
      showMoreButton,
    ],
  );

  const styleFontSizeTitle = useMemo(
    () => ({
      fontSize: shouldUsePostListMobileTitleFontSize ? postTitleFontSize : defaultPostTitleFontSize,
    }),
    [shouldUsePostListMobileTitleFontSize, postTitleFontSize, defaultPostTitleFontSize],
  );

  const Title = useMemo(
    () => () =>
      (
        <div
          style={styleFontSizeTitle}
          className={classNames(titleContainerClassName, alignmentStyles.textAlign)}
          data-hook="post-list-item__title"
        >
          <PostListItemProGalleryTitle
            type={type}
            title={post?.title}
            style={styleFontSizeTitle}
            lineCount={lineCount?.title}
            titleLineCount={titleLineCount}
            showCategoryLabel={feedMetadataSettings.showCategoryLabel}
            isHoverDisabled={isHoverDisabled}
          />
          {!layoutList && (
            <PostListItemProGalleryRatingsDisplay
              post={post}
              type={type}
              withoutFooter={withoutFooter}
              section={section}
            />
          )}
        </div>
      ),
    [
      styleFontSizeTitle,
      titleContainerClassName,
      type,
      lineCount?.title,
      titleLineCount,
      feedMetadataSettings.showCategoryLabel,
      isHoverDisabled,
      layoutList,
      post,
      withoutFooter,
      section,
    ],
  );

  return (
    <div className={titleContainer}>
      {isHoverDisabled ? (
        <Title />
      ) : (
        <PostLink
          postLink={isHoverDisabled ? undefined : post?.link}
          postPath={post?.slug!}
          className={titleLinkClassName}
        >
          <Title />
        </PostLink>
      )}
      <PostListItemProGalleryDescription
        post={post}
        lineCount={lineCount}
        type={type}
        showMoreButton={showMoreButton}
        withoutFooter={withoutFooter}
        descriptionLineCount={descriptionLineCount}
        isHoverDisabled={isHoverDisabled}
        withDescription={withDescription}
      />
    </div>
  );
};
