import { ContentAlignment } from '@wix/communities-blog-client-common';

export const getContentAlignmentStyles = (contentAlignment: ContentAlignment, isRTL: boolean) => {
  switch (contentAlignment) {
    case ContentAlignment.Center:
      return 'center';
    case ContentAlignment.Right:
      return isRTL ? 'start' : 'end';
    case ContentAlignment.Left:
    default:
      return isRTL ? 'end' : 'start';
  }
};
