import { useMemo } from 'react';
import {
  type Section,
  SECTION_HOMEPAGE,
  SECTION_MY_POSTS,
  SECTION_POST_LIST,
  SECTION_RELATED_POSTS,
} from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';
import { getRoute } from '../router/router-selectors';
import {
  getIsCreatedWithResponsiveEditor,
  getIsFeedDesignPropsMigrated,
  getUseMobileDesignSettings,
} from '../selectors/app-settings-selectors';
import { shouldApplyPostDesignInFeed as getShouldApplyPostDesignInFeed } from '../selectors/full-post-selectors';
import { getSection } from '../selectors/section-selectors';
import { isInRouteUsingFeedPage } from '../services/detect-route';
import { createFeedClassNameGenerator } from '../services/layout-config';

export const useIsFeedDesignEnabled = (sectionFromProps: Section = SECTION_HOMEPAGE) => {
  const section = useSelector((state) => getSection(state) || sectionFromProps);
  const route = useSelector(getRoute);
  const isCreatedWithResponsiveEditor = useSelector(getIsCreatedWithResponsiveEditor);
  const isFeedDesignPropsMigrated = useSelector(getIsFeedDesignPropsMigrated);
  const useMobileSettings = useSelector(getUseMobileDesignSettings);
  const shouldApplyPostDesignInFeed = useSelector(getShouldApplyPostDesignInFeed);
  const isInSearch = false; // TODO: isInSearchPage(route);
  const shouldApplyDesignInFeed = isInRouteUsingFeedPage(route) && !shouldApplyPostDesignInFeed;
  const applyFeedDesign =
    section === SECTION_POST_LIST ||
    section === SECTION_RELATED_POSTS ||
    section === SECTION_MY_POSTS ||
    ((isFeedDesignPropsMigrated || isCreatedWithResponsiveEditor) &&
      (shouldApplyDesignInFeed || isInSearch));

  const getPostClassName: (...classNames: string[]) => string[] = useMemo(
    () => (applyFeedDesign ? createFeedClassNameGenerator(section, useMobileSettings) : () => []),
    [applyFeedDesign, section, useMobileSettings],
  );

  return {
    getPostClassName,
    applyFeedDesign,
    section,
  };
};

export default useIsFeedDesignEnabled;
