import { once } from 'lodash';
import type PostActions from './post-actions';

export const importPostActions = once(async () => {
  return (
    await import(
      /* webpackChunkName: "post-actions" */
      './post-actions'
    )
  ).default as typeof PostActions;
});
