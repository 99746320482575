import { raise } from '@wix/communities-blog-client-common';

/**
 * @deprecated Does not work well with Typescript. It's better to define
 * the selector arguments inline:
 *
 * @example
 * const getPublishedPosts = createSelector(
 *   [
 *     getAllPosts,
 *     (_, status: Status) => status
 *   ]
 * )
 **/
// @ts-expect-error
export const secondParam = (_1, _2) => _2 || raise(`Missing second param in selector`);
