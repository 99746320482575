import React, { type PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './skeleton-renderer.scss';

type SkeletonRendererProps = PropsWithChildren<{
  isLoaded: boolean;
  width?: number;
  className?: string;
}>;

const SkeletonRenderer: React.FC<SkeletonRendererProps> = ({
  isLoaded,
  children,
  className,
  width,
}) => {
  /** Makes sure skeleton loader bounding box is the same size as the text it's going to display */
  const BLANK_TEXT_FOR_BETTER_LCP = <>&nbsp;</>;

  if (isLoaded) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div
      className={classNames(styles.root, className)}
      style={{ '--width': width ? `${width}px` : '300px' }}
    >
      {BLANK_TEXT_FOR_BETTER_LCP}
    </div>
  );
};

export default SkeletonRenderer;
