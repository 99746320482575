import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import UserAvatarImage from '../user-avatar-image';
import UserName from '../user-name';
import styles from './avatar.scss';

const Avatar = ({
  type = Avatar.DEFAULT,
  user = {},
  isImageVisible = true,
  isNameVisible = true,
}) => {
  const className = classNames(
    styles.container,
    'blog-text-color',
    'blog-link-hover-color',
    'avatar',
    'blog-post-text-color',
  );
  return (
    <span className={className}>
      {isImageVisible && <UserAvatarImage type={type} user={user} />}
      {isNameVisible && user.name && <UserName className={styles.name} name={user.name} />}
    </span>
  );
};

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
  type: PropTypes.string,
  isImageVisible: PropTypes.bool,
  isNameVisible: PropTypes.bool,
};

export default Avatar;
