import React, { useCallback } from 'react';
import classNames from 'classnames';
import { createRtfFormatter, useTranslation } from '@wix/yoshi-flow-editor';
import { formatDateICU } from '@wix/communities-blog-client-common';
import { getLanguage, getTimezone } from '../../store/basic-params/basic-params-selectors';
import { useSelector } from '../runtime-context';

type Props = {
  className?: string;
  time: string | number | Date | null | undefined;
  timeFormatted?: string;
};

const TimeAgo: React.FC<Props> = ({ className, time, timeFormatted }) => {
  const { t } = useTranslation();
  const formatRelativeTime = useRelativeTimeFormatter();
  const timeAgo = t(timeFormatted || formatRelativeTime(time));

  return (
    <span title={timeAgo} className={classNames(className, 'time-ago')} data-hook="time-ago">
      {timeAgo}
    </span>
  );
};

const useRelativeTimeFormatter = () => {
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);
  const rtfFormatter = createRtfFormatter(language);
  const formatRelativeTime = useCallback(
    (time: string | number | Date | null | undefined) => {
      if (!time) {
        return '';
      }

      return formatDateICU({
        date: typeof time === 'number' ? new Date(time) : time,
        lng: language,
        rtfFormatter: rtfFormatter as any,
        timezone,
      });
    },
    [language, rtfFormatter, timezone],
  );

  return formatRelativeTime;
};

export default TimeAgo;
