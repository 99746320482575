import { useSelector } from '../components/runtime-context';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';

export type UseDeviceType = {
  isMobile: boolean;
  isDesktop: boolean;
};

export default function useDeviceType(): UseDeviceType {
  const isMobile = useSelector(getIsMobile);
  const isDesktop = !isMobile;

  return {
    isMobile,
    isDesktop,
  };
}
