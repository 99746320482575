import React from 'react';
import classNames from 'classnames';
import StarIcon from './star-icon';
import styles from './rating-stars.scss';

export const RatingStars = ({ rating, a11yLabel }) => {
  const normalizedRating = Math.min(Math.max(rating, 0), 5);
  return (
    <span className={styles.root}>
      {a11yLabel && <span className={styles.visuallyHidden}>{a11yLabel}</span>}
      <span aria-hidden="true" className={styles.innerContainer}>
        {[1, 2, 3, 4, 5].map((index) => {
          const iconState = toStarState(normalizedRating, index);

          return iconState === 'half' ? (
            <span className={styles.halfStarWrapper} key={index}>
              <StarIcon
                key={`${index}-start`}
                className={classNames(styles.icon, styles.halfStarInnerWrapperStart, styles.full)}
              />
              <StarIcon
                key={`${index}-end`}
                className={classNames(styles.icon, styles.halfStarInnerWrapperEnd, styles.empty)}
              />
            </span>
          ) : (
            <StarIcon
              key={index}
              className={classNames(styles.icon, iconState === 'full' ? styles.full : styles.empty)}
            />
          );
        })}
      </span>
    </span>
  );
};

/* index is 1 to 5, like rating */
function toStarState(rating, index) {
  if (index <= rating) {
    return 'full';
  }
  if (index - rating < 1) {
    const partial = index - rating;
    return partial < 0.25 ? 'full' : partial > 0.75 ? 'empty' : 'half';
  }
  return 'empty';
}
