import React from 'react';

export const PinIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    viewBox="0 0 19 19"
    role="img"
    style={{
      fillRule: 'nonzero',
    }}
    {...props}
  >
    <path d="M7 14.33l3-2.933 3 2.934V3.978H7v10.353zM7 3h6a.99.99 0 0 1 1 .978v11.533c0 .27-.224.489-.5.489a.506.506 0 0 1-.354-.143L10 12.78l-3.146 3.077a.508.508 0 0 1-.708 0A.484.484 0 0 1 6 15.51V3.978A.99.99 0 0 1 7 3z" />
  </svg>
);
