import {
  PostListWidgetPaginationType,
  postListWidgetStyleParams,
} from '@wix/communities-blog-client-common';
import {
  getAppSettingsValue,
  getAppSettingsNumber,
} from '../../common/selectors/app-settings-base-selectors';
import type { AppState } from '../../common/types';

export const getPostListWidgetPageSize = (state: AppState) => {
  const _paginationType = getAppSettingsValue({
    state,
    key: `style.numbers.${postListWidgetStyleParams.postListWidgetPaginationType.key}`,
    fallback: postListWidgetStyleParams.postListWidgetPaginationType
      .defaultValue as unknown as number,
  });

  const pageSizeParam =
    Number(_paginationType) === Number(PostListWidgetPaginationType.Paginated)
      ? postListWidgetStyleParams.postListWidgetPostsPerPage
      : postListWidgetStyleParams.postListWidgetEntityCount;

  return getAppSettingsNumber(state, pageSizeParam.key, pageSizeParam.defaultValue);
};
