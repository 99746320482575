import React from 'react';
import { trimEnd } from 'lodash';
import { getBaseUrl } from '../../store/topology/topology-selectors';
import { useSelector } from '../runtime-context';

type Props = {
  to?: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const SiteLink: React.FC<Props> = ({ to, children, ...props }) => {
  const baseUrl = useSelector(getBaseUrl);

  return (
    <a href={`${trimEnd(baseUrl, '/')}${to}`} {...props}>
      {children}
    </a>
  );
};

export default SiteLink;
