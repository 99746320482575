import React, { createContext, useContext } from 'react';

export type WithResponsiveContext = {
  rootWidth: number | undefined;
};

export const useResponsiveContext = () => {
  return useContext(context);
};

const context = createContext<WithResponsiveContext>({
  rootWidth: undefined,
});

export function withResponsiveContext<P extends object>(
  Component: React.ComponentType<P & WithResponsiveContext>,
) {
  const WithResponsiveContext: React.FC<P> = (props) => {
    const value = useResponsiveContext();
    return <Component {...props} {...value} />;
  };

  return WithResponsiveContext;
}

export default context;
