import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  type LayoutName,
  type PostAction,
  isLayoutNameTextOnImage,
} from '@wix/communities-blog-client-common';
import useFontClassName from '../../hooks/use-font-class-name';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import styles from '../../styles/post-list-item.scss';
import { type NormalizedPost } from '../../types';
import PostListItemHeader from '../post-list-item-header';

type Props = {
  post: NormalizedPost;
  type: LayoutName | undefined;
  showMoreButton: boolean;
  visibleActions: PostAction[] | undefined;
  styleFontSizeMetadata?: React.CSSProperties;
};

export const PostListItemProGalleryHeader = ({
  post,
  type,
  showMoreButton,
  visibleActions,
  styleFontSizeMetadata,
}: Props) => {
  const { getPostClassName } = useIsFeedDesignEnabled();
  const { contentFontClassName } = useFontClassName();

  const headerClassName = useMemo(
    () =>
      classNames(
        styles.headerWrapper,
        contentFontClassName,
        getPostClassName('description-font'),
        alignmentStyles.headerContainer,
      ),
    [contentFontClassName, getPostClassName],
  );

  return (
    <PostListItemHeader
      className={headerClassName}
      post={post}
      showMoreButton={showMoreButton}
      showProfileImage={!isLayoutNameTextOnImage(type)}
      style={styleFontSizeMetadata}
      type={type}
      visibleActions={visibleActions}
    />
  );
};
