import React from 'react';
import memoizeOne from 'memoize-one';
import { type ConnectedProps } from 'react-redux';
import { connect } from '../../components/runtime-context';

type PageUrlBuilder = (page: number) => string | undefined;
export type HandleNavigationFn = (navigation: {
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>;
  pageUrl: string | undefined;
  navigateInPreview: (link?: any, sectionId?: any) => void;
}) => void;

type PageLinkProps = {
  children: React.ReactNode;
  page: number;
  isDisabled: boolean;
  isActive: boolean;
} & React.HTMLAttributes<HTMLElement>;

export const createPageLink = memoizeOne(
  (createPageUrl: PageUrlBuilder, handleNavigation: HandleNavigationFn = () => {}) => {
    const PageLink = ({
      children,
      page,
      isDisabled,
      isActive,
      navigateInPreview,
      ...props
    }: PageLinkProps & ConnectedProps<typeof connector>) => {
      if (isActive || isDisabled) {
        return <button {...props}>{children}</button>;
      }

      const pageUrl = createPageUrl(page);

      return (
        <a
          href={pageUrl}
          onClick={(event) =>
            handleNavigation({
              event,
              pageUrl,
              navigateInPreview,
            })
          }
          {...props}
        >
          {children}
        </a>
      );
    };

    const connector = connect((_state, _ownProps, actions) => ({
      navigateInPreview: actions.navigateInPreview,
    }));

    return connector(PageLink);
  },
);
