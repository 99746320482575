import React from 'react';
import classNames from 'classnames';
import { PAGE_NUMBERS_TO_SHOW, PaginationButtonType } from '../constants';
import { type PaginationState } from '../hooks/use-pagination-state';
import styles from '../pagination.scss';

type PaginationButtonTypeWithRules =
  | PaginationButtonType.FIRST
  | PaginationButtonType.PREVIOUS
  | PaginationButtonType.NEXT
  | PaginationButtonType.LAST;

type ArrowRule = () => {
  isEnabled: boolean;
  isVisible: boolean;
  pageToOpen: number;
};

type PaginationArrowsProps = React.PropsWithChildren<{
  state: PaginationState;
  showPosition: boolean;
  type: PaginationButtonTypeWithRules;
  label: string;
  arrowClass?: string;
  disabledClass?: string;
  component: React.FC<any>;
}>;

const PaginationArrows: React.FC<PaginationArrowsProps> = ({
  children,
  label,
  arrowClass,
  disabledClass,
  type,
  state,
  showPosition,
  component: Component,
}: PaginationArrowsProps) => {
  const arrowContextByType: Record<PaginationButtonTypeWithRules, ArrowRule> = {
    [PaginationButtonType.FIRST]: () => ({
      isEnabled: state.currentPage > (showPosition ? 1 : 3),
      isVisible: state.lastPage > (showPosition ? 2 : PAGE_NUMBERS_TO_SHOW),
      pageToOpen: 1,
    }),
    [PaginationButtonType.PREVIOUS]: () => ({
      isEnabled: state.currentPage > 1,
      isVisible: true,
      pageToOpen: state.currentPage - 1,
    }),
    [PaginationButtonType.NEXT]: () => ({
      isEnabled: state.currentPage < state.lastPage,
      isVisible: true,
      pageToOpen: state.currentPage + 1,
    }),
    [PaginationButtonType.LAST]: () => ({
      isEnabled: state.currentPage < state.lastPage - (showPosition ? 0 : 2),
      isVisible: state.lastPage > (showPosition ? 2 : PAGE_NUMBERS_TO_SHOW),
      pageToOpen: state.lastPage,
    }),
  };

  const { isVisible, isEnabled, pageToOpen } = arrowContextByType[type]();

  if (!isVisible) {
    return null;
  }

  const eventHandlers = isEnabled ? state.getButtonEventHandlers(pageToOpen, type) : undefined;

  return (
    <Component
      {...eventHandlers}
      page={pageToOpen}
      isDisabled={!isEnabled}
      className={classNames(styles[type], arrowClass, !isEnabled && disabledClass)}
      aria-label={label}
      data-hook={`pagination__${type}`}
    >
      {children}
    </Component>
  );
};

export default PaginationArrows;
