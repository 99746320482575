import { get } from 'lodash';
import {
  getPostDescriptionFontSize,
  getPostTitleFontSize,
  type Layout,
  type Section,
} from '@wix/communities-blog-client-common';
import type { AppState } from '@app/external/common/types';
import {
  getCategoryLabelBorderWidth,
  getCategoryLabelTextFont,
  getCategoryLabelVerticalPadding,
  getIsCategoryLabelLayoutText,
} from '../../../../post-list-widget/selectors/category-label-selectors';
import { getAppSettings } from '../../../selectors/app-settings-base-selectors';
import {
  getIsAuthorNameEnabled,
  getIsCategoryLabelEnabled,
  getIsCommentCountEnabled,
  getIsLikeCountEnabled,
  getIsPostDescriptionEnabled,
  getIsPostPublishDateEnabled,
  getIsReadingTimeEnabled,
  getIsViewCountEnabled,
  getLayoutDescriptionLineCount,
  getLayoutTitleLineCount,
} from '../../../selectors/app-settings-selectors';
import { getIsMobile } from '../../../store/basic-params/basic-params-selectors';
import {
  DEFAULT_CATEGORY_LABEL_LINE_HEIGHT,
  DEFAULT_DESCRIPTION_LINE_HEIGHT,
  DEFAULT_TITLE_LINE_HEIGHT,
  type CONSTANTS,
} from './constants';

export const getFontLineHeight = (
  blogFontSize: number | null,
  defaultSize: number,
  defaultLineHeight: number,
) => {
  return blogFontSize ? defaultLineHeight * blogFontSize : defaultLineHeight * defaultSize;
};

type SizeConstant = (typeof CONSTANTS)[keyof typeof CONSTANTS];

export const getCategoryLabelSize = (
  state: AppState,
  constants: Pick<SizeConstant, 'CATEGORY_LABEL_MARGIN'>,
) => {
  const isCategoryLabelEnabled = getIsCategoryLabelEnabled(state);
  if (!isCategoryLabelEnabled) {
    return 0;
  }
  const categoryLabelFont = getCategoryLabelTextFont(state);

  if (getIsCategoryLabelLayoutText(state)) {
    return (
      categoryLabelFont.size * DEFAULT_CATEGORY_LABEL_LINE_HEIGHT + constants.CATEGORY_LABEL_MARGIN
    );
  } else {
    const fontSize = categoryLabelFont.size;
    const borderWidth = getCategoryLabelBorderWidth(state);
    const verticalPadding = getCategoryLabelVerticalPadding(state);
    return (
      fontSize * DEFAULT_CATEGORY_LABEL_LINE_HEIGHT +
      borderWidth * 2 +
      verticalPadding * 2 +
      constants.CATEGORY_LABEL_MARGIN
    );
  }
};

export const getHeaderSize = (state: AppState, constants: Pick<SizeConstant, 'HEADER_HEIGHT'>) => {
  const isReadingTimeEnabled = getIsReadingTimeEnabled(state);
  const isPostPublishDateEnabled = getIsPostPublishDateEnabled(state);
  const isAuthorNameEnabled = getIsAuthorNameEnabled(state);

  const isHeaderEnabled = isAuthorNameEnabled || isPostPublishDateEnabled || isReadingTimeEnabled;
  return isHeaderEnabled ? constants.HEADER_HEIGHT : 0;
};

export const getFooterSize = (state: AppState, constants: Pick<SizeConstant, 'FOOTER_HEIGHT'>) => {
  const isCommentCountEnabled = getIsCommentCountEnabled(state);
  const isViewCountEnabled = getIsViewCountEnabled(state);
  const isLikeCountEnabled = getIsLikeCountEnabled(state);

  const isFooterEnabled = isCommentCountEnabled || isViewCountEnabled || isLikeCountEnabled;

  return isFooterEnabled ? constants.FOOTER_HEIGHT : 0;
};

export const getTitleSize = (
  state: AppState,
  section: Section,
  layoutType: Layout,
  constants: Pick<SizeConstant, 'DEFAULT_TITLE_FONT_SIZE' | 'TITLE_MARGIN'>,
) => {
  const appSettings = getAppSettings(state);
  const titleMaxLines = getLayoutTitleLineCount({ state, section, layoutType }) || 3;
  const titleFont = get(appSettings, `style.fonts.post-${section}-titleFont`) ?? {};
  const fontSize = getIsMobile(state)
    ? get(appSettings, `style.numbers.${getPostTitleFontSize(layoutType, section, true)}`)
    : titleFont.size;

  const lineHeight = getFontLineHeight(
    fontSize || titleFont.size,
    constants.DEFAULT_TITLE_FONT_SIZE,
    DEFAULT_TITLE_LINE_HEIGHT,
  );

  return lineHeight * titleMaxLines + constants.TITLE_MARGIN;
};

export const getDescriptionSize = (
  state: AppState,
  section: Section,
  layoutType: Layout,
  constants: Pick<SizeConstant, 'DEFAULT_DESCRIPTION_FONT_SIZE'>,
) => {
  const isPostDescriptionEnabled = getIsPostDescriptionEnabled(state);
  if (!isPostDescriptionEnabled) {
    return 0;
  }
  const appSettings = getAppSettings(state);
  const descriptionFont = get(appSettings, `style.fonts.post-${section}-descriptionFont`) ?? {};
  const descriptionMaxLines = getLayoutDescriptionLineCount({ state, section, layoutType }) || 3;

  const fontSize = getIsMobile(state)
    ? get(appSettings, `style.numbers.${getPostDescriptionFontSize(layoutType, section, true)}`)
    : descriptionFont.size;

  const lineHeight = getFontLineHeight(
    fontSize || descriptionFont.size,
    constants.DEFAULT_DESCRIPTION_FONT_SIZE,
    DEFAULT_DESCRIPTION_LINE_HEIGHT,
  );

  return lineHeight * descriptionMaxLines;
};
