import { getIsRTL } from '../../../store/basic-params/basic-params-selectors';
import { ArrowLeftIcon } from '../../icons/arrow-left-icon';
import { ArrowRightIcon } from '../../icons/arrow-right-icon';
import { useSelector } from '../../runtime-context';

export function useArrowIcons() {
  const isRTL = useSelector(getIsRTL);

  const getArrow = (side: 'left' | 'right') => {
    if (isRTL) {
      return side === 'right' ? ArrowLeftIcon : ArrowRightIcon;
    }

    return side === 'right' ? ArrowRightIcon : ArrowLeftIcon;
  };

  return {
    Left: getArrow('left'),
    Right: getArrow('right'),
  };
}
