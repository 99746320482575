import React, { useCallback, useEffect, useRef } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useIsVisible } from './use-element-visiblity';
import s from './load-more.scss';

export type LoadMoreProps = React.PropsWithChildren<{
  hasMore?: boolean;
  /** Page from which component starts tracking the current page */
  pageStart?: number;
  loadMore: (page: number) => Promise<any>;
  loader?: React.ReactNode;
  isLoading?: boolean;
}>;

const PAGES_TO_LOAD_IN_EDITOR = 3;

const LoadMore: React.FC<LoadMoreProps> = ({
  hasMore = false,
  pageStart = 2,
  children,
  loader,
  isLoading: isLoadingProp,
  loadMore,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const ref = useRefPage(pageStart);
  const { isSSR, isEditor } = useEnvironment();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const isVisible = useIsVisible({ scrollRef, delay: isEditor ? 1000 : 0 });

  const handleLoad = useCallback(() => {
    if (isLoadingProp || isLoading) {
      return;
    }

    setIsLoading(true);

    loadMore(ref.current.page).finally(() => {
      setIsLoading(false);
    });

    ref.current.page++;
  }, [isLoadingProp, isLoading, loadMore, ref]);

  const shouldLoadMore = React.useMemo(() => {
    if (!hasMore || isLoadingProp || isLoading || isSSR) {
      return false;
    }

    if (isEditor && ref.current.page > PAGES_TO_LOAD_IN_EDITOR) {
      return false;
    }

    return true;
  }, [hasMore, isEditor, isLoading, isLoadingProp, isSSR, ref]);

  useEffect(() => {
    if (isVisible && shouldLoadMore) {
      handleLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <div>
      {children}
      <div className={s.scrollRef} ref={scrollRef} />
      {hasMore && (isLoadingProp || isLoading) && loader}
    </div>
  );
};

const useRefPage = (pageStart: number) => {
  const ref = React.useRef({ page: pageStart, pageStart });

  // Reset page when pageStart prop changes
  useEffect(() => {
    const pageStartChanged = pageStart !== ref.current.pageStart;

    if (pageStartChanged) {
      ref.current = { page: pageStart, pageStart };
    }
  }, [pageStart]);

  return ref;
};

export default LoadMore;
