import React, { useRef, useState, Suspense } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { useTranslation } from '@wix/yoshi-flow-editor';
import useFontClassName from '../../hooks/use-font-class-name';
import useLayoutColorClasses from '../../hooks/use-layout-color-classes';
import { isSite as isSiteSelector } from '../../store/basic-params/basic-params-selectors';
import MoreIcon from '../icons/more-icon';
import Loader from '../loader';
import PopoverRoot from '../popovers/popover-root';
import { useSelector } from '../runtime-context';
import { useGetContainerPosition } from './more-button-hook';
import styles from './more-button.scss';

type MoreButtonProps = {
  children: () => React.ReactNode | Promise<React.ReactNode>;
  className?: string;
  isWhite?: boolean;
  id?: string;
  icon?: React.ReactNode;
  container?: HTMLElement;
};

const MoreButton: React.FC<MoreButtonProps> = ({
  children,
  className: buttonClassName,
  isWhite,
  id,
  icon,
  container,
}) => {
  const containerRef = useRef<HTMLButtonElement | null>(null);
  const actionsContainerRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [component, setComponent] = useState<React.ReactNode | null>(null);
  const { t } = useTranslation();

  const { contentFontClassName } = useFontClassName();
  const { iconColorClassName } = useLayoutColorClasses();
  const componentId = useSelector((_, host) => host.id);
  const isSite = useSelector(isSiteSelector);

  const { checkIfFits, checkIfFitsInEditor, calculateActionsContainerPosition } =
    useGetContainerPosition({
      actionsContainerRef,
      containerRef,
      componentId,
      container,
    });

  const showComponent = async () => {
    const renderedComponent = children() instanceof Promise ? await children() : children();
    setComponent(renderedComponent);
    document.addEventListener('click', hideComponent, { once: true });
    setIsVisible(true);
    isSite ? checkIfFits() : checkIfFitsInEditor();
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isVisible) {
      hideComponent();
    } else {
      showComponent();
    }
  };

  const hideComponent = () => {
    document.removeEventListener('click', hideComponent);
    setIsVisible(false);
  };

  const renderActionsContainer = () => {
    const actionsContainerClass = classNames(
      styles.actions,
      contentFontClassName,
      'blog-text-color',
      'blog-dropdown-background-color',
    );

    const position = calculateActionsContainerPosition();

    const FocusTrap = React.lazy(() => import('focus-trap-react'));

    return ReactDOM.createPortal(
      <div
        ref={actionsContainerRef}
        className={actionsContainerClass}
        style={position}
        role="menu"
        data-hook="actions"
      >
        <Suspense fallback={<Loader />}>
          <FocusTrap
            focusTrapOptions={{
              allowOutsideClick: true,
              preventScroll: false,
              escapeDeactivates: true,
              fallbackFocus: containerRef.current!,
            }}
          >
            {component}
          </FocusTrap>
        </Suspense>
      </div>,
      container ?? PopoverRoot.getPopoverRootElement()!,
    );
  };

  const className = classNames(buttonClassName, styles.more, 'more-button', {
    [styles.moreWhite]: isWhite,
  });

  const handleKeyUp = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Escape') {
      hideComponent();
    }
  };

  return (
    <>
      <button
        onClick={handleClick}
        className={className}
        ref={containerRef}
        onKeyUp={handleKeyUp}
        type="button"
        aria-pressed={isVisible}
        aria-label={t('more-button.more-actions')}
        id={id}
        data-hook="more-button"
      >
        <span className={styles.icon}>
          {icon || (
            <MoreIcon
              className={classNames(
                iconColorClassName,
                'blog-post-homepage-link-hashtag-hover-fill',
              )}
            />
          )}
        </span>
      </button>
      {isVisible ? renderActionsContainer() : null}
    </>
  );
};

export default MoreButton;
