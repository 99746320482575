import React from 'react';
import classNames from 'classnames';
import List from '../../../common/components/list';

import { BulletSeparatorForPost } from '../../../common/components/separator';

import useIsFeedDesignEnabled from '../../../common/hooks/use-is-feed-design-enabled';
import styles from './post-metadata-layout.scss';

const PostMetadataLayout: React.FC<{
  className?: string;
  itemClassName?: string;
}> = ({ className, itemClassName, children }) => {
  const { applyFeedDesign, getPostClassName } = useIsFeedDesignEnabled();

  const separatorProps = React.useMemo(() => {
    if (applyFeedDesign) {
      return {
        className: styles.separator,
        separatorBackgroundColorClassName: classNames(
          getPostClassName('description-background-color'),
        ),
      };
    }
    return {
      className: styles.separator,
    };
  }, [applyFeedDesign, getPostClassName]);

  return (
    <List
      itemClassName={itemClassName ? itemClassName : styles.item}
      containerClassName={classNames(styles.container, className)}
      separator={<BulletSeparatorForPost {...separatorProps} />}
      native={false}
      children={children}
    />
  );
};

export default PostMetadataLayout;
