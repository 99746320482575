import { useCallback } from 'react';
import {
  getCurrentUser,
  isBlocked as getIsBlocked,
  isPrivate as getIsPrivate,
} from '@wix/communities-blog-client-common';
import { useActions, useSelector } from '../components/runtime-context';
import { isAuthenticated as getIsAuthenticated } from '../store/auth/auth-selectors';
import { isSite as getIsSite } from '../store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';

type ForPublicUserCallback = (fn: Function, options?: { preventDefault?: boolean }) => any;

export type UseAuth = {
  currentUser: any;
  isAuthenticated: any;
  isBlocked: any;
  isPrivate: any;
  forPublicUser: ForPublicUserCallback;
};

export default function useAuth(): UseAuth {
  const actions = useActions();
  const currentUser = useSelector(getCurrentUser);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isBlocked = useSelector(getIsBlocked);
  const isPrivate = useSelector(getIsPrivate);
  const isMemberAreaActive = useSelector(getIsMemberAreaInstalled);
  const isSite = useSelector(getIsSite);

  const decorateWithPrivacyChecker = useCallback<ForPublicUserCallback>(
    (fn, { preventDefault = false } = {}) => {
      return function (this: any, ...args: any[]) {
        if (preventDefault) {
          const event = args[0];
          event.preventDefault && event.preventDefault();
        }

        if (isAuthenticated && isPrivate) {
          actions.showJoinCommunityPopup();
        } else {
          return fn.apply(this, args);
        }
      };
    },
    [actions, isAuthenticated, isPrivate],
  );

  const forPublicUser = useCallback<ForPublicUserCallback>(
    (fn, options) => {
      if (!isMemberAreaActive || !isSite) {
        return fn;
      }

      return decorateWithPrivacyChecker(fn, options);
    },
    [isMemberAreaActive, isSite, decorateWithPrivacyChecker],
  );

  return {
    currentUser,
    isAuthenticated,
    isBlocked,
    isPrivate,
    forPublicUser,
  };
}
