import { useState, useEffect, type RefObject } from 'react';

export const useScrollBase = (containerRef: RefObject<HTMLElement>): number => {
  const [scrollBase, setScrollBase] = useState(0);
  useEffect(() => {
    let observedContainer: HTMLElement | null = null;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && observedContainer) {
        const rect = observedContainer.getBoundingClientRect();
        setScrollBase(rect.top + window.scrollY);
      }
    });

    if (containerRef.current) {
      observedContainer = containerRef.current;
      const rect = observedContainer.getBoundingClientRect();
      setScrollBase(rect.top + window.scrollY);
      observer.observe(observedContainer);
    }
    return () => {
      if (observedContainer) {
        observer.unobserve(observedContainer);
      }
    };
  }, [containerRef]);

  return scrollBase;
};
