import React from 'react';
import classNames from 'classnames';
import { trimStart } from 'lodash';
import { ROUTE_PREFIX_CATEGORIES, SECTION_BLOG_PAGE } from '@wix/communities-blog-client-common';
import BaseLink from './base-link';
import styles from './category-link.scss';

type Props = {
  categoryLink: string;
  categoryPath: string;
} & Omit<React.ComponentProps<typeof BaseLink>, 'href' | 'path' | 'sectionId'>;

const CategoryLink: React.FC<Props> = ({ categoryLink, categoryPath, children, ...rest }) => {
  return (
    <BaseLink
      {...rest}
      href={categoryLink}
      path={`${ROUTE_PREFIX_CATEGORIES}/${trimStart(categoryPath, '/')}`}
      sectionId={SECTION_BLOG_PAGE}
      className={classNames(styles.category, rest.className)}
    >
      {children}
    </BaseLink>
  );
};

export default CategoryLink;
