import { flowRight } from 'lodash';
import ProvisioningPage from '../containers/provisioning-page';
import { withDirection } from './with-direction';
import { withInvalidState } from './with-invalid-state';
import { withLayoutUpdate } from './with-layout-update';
import { withProvisioning } from './with-provisioning';

export function withViewer(AppRoot) {
  return flowRight(
    withDirection,
    withProvisioning(ProvisioningPage),
    withInvalidState,
    withLayoutUpdate,
  )(AppRoot);
}
