import React from 'react';
import { omit } from 'lodash';

type PaginationContainerProps = React.PropsWithChildren<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>;

const PaginationContainer: React.FC<PaginationContainerProps> = ({ children, ...props }) => (
  <div {...omit(props, ['page', 'isDisabled', 'isActive'])}>{children}</div>
);

export default PaginationContainer;
