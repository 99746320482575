export const postListCssVars = {
  COMMON_PADDING_TOP: 28,
  COMMON_PADDING_BOTTOM: 25,
  SLIDER_PADDING_TOP: 16,
  SLIDER_PADDING_BOTTOM: 24,

  HEADER_HEIGHT: 34,
  HEADER_MARGIN_BOTTOM: 18,

  FOOTER_CONTENT_HEIGHT: 18,
  FOOTER_SEPARATOR_MARGIN: 15,
  FOOTER_PADDING_TOP: 8,

  COMMON_TITLE_MARGIN: 11,
  DEFAULT_TITLE_MARGIN: 10,
  SLIDER_TITLE_MARGIN_TOP: 10,

  CATEGORY_LABEL_MARGIN_BOTTOM: 12,
  SLIDER_CATEGORY_LABEL_MARGIN_BOTTOM: 2,

  BORDER_WIDTH: 1,
};

export const getPostListCssVars = () =>
  ({
    '--blog-pl-common-padding-top': `${postListCssVars.COMMON_PADDING_TOP}px`,
    '--blog-pl-common-padding-bottom': `${postListCssVars.COMMON_PADDING_BOTTOM}px`,
    '--blog-pl-slider-padding-top': `${postListCssVars.SLIDER_PADDING_TOP}px`,
    '--blog-pl-slider-padding-bottom': `${postListCssVars.SLIDER_PADDING_BOTTOM}px`,

    '--blog-pl-header-height': `${postListCssVars.HEADER_HEIGHT}px`,
    '--blog-pl-header-margin-bottom': `${postListCssVars.HEADER_MARGIN_BOTTOM}px`,

    '--blog-pl-footer-content-height': `${postListCssVars.FOOTER_CONTENT_HEIGHT}px`,
    '--blog-pl-footer-separator-margin': `${postListCssVars.FOOTER_SEPARATOR_MARGIN}px`,
    '--blog-pl-footer-padding-top': `${postListCssVars.FOOTER_PADDING_TOP}px`,

    '--blog-pl-common-title-margin': `${postListCssVars.COMMON_TITLE_MARGIN}px`,
    '--blog-pl-default-title-margin': `${postListCssVars.DEFAULT_TITLE_MARGIN}px`,
    '--blog-pl-slider-title-margin-top': `${postListCssVars.SLIDER_TITLE_MARGIN_TOP}px`,

    '--blog-pl-category-label-margin-bottom': `${postListCssVars.CATEGORY_LABEL_MARGIN_BOTTOM}px`,
    '--blog-pl-slider-category-label-margin-bottom': `${postListCssVars.SLIDER_CATEGORY_LABEL_MARGIN_BOTTOM}px`,
  } as const);
