import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  getWixDataCategoryId,
  getWixDataTagId,
  postListWidgetStyleParams as params,
} from '@wix/communities-blog-client-common';
import EmptyStates from '../../../common/components/empty-states';
import { useSelector } from '../../../common/components/runtime-context';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import { getQueryLocale } from '../../../common/selectors/locale-selectors';
import {
  getIsEditorSegment,
  isEditor as getIsEditor,
} from '../../../common/store/basic-params/basic-params-selectors';

type Props = React.ComponentProps<typeof EmptyStates>;

const NoPosts: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  const { showUnifiedStateText, isMultilingual, showFilterWarning } = useNoPostsSelector();

  return (
    <EmptyStates
      title={
        showUnifiedStateText
          ? t('no-posts-feed.nothing-to-show')
          : showFilterWarning
          ? t('no-posts.filtered.title')
          : isMultilingual
          ? t('no-posts-feed.no-published-posts-multilingual')
          : t('no-posts-feed.on-the-way')
      }
      content={
        showUnifiedStateText
          ? t('no-posts.publish-posts-to-see-them')
          : showFilterWarning
          ? t('no-posts.filtered.subtitle')
          : t('no-posts-feed.stay-tuned')
      }
      {...props}
    />
  );
};

const useNoPostsSelector = () => {
  const isEditorSegment = useSelector(getIsEditorSegment);
  const isEditor = useSelector(getIsEditor);
  const wixDataCategoryId = useSelector(getWixDataCategoryId);
  const wixDataTagId = useSelector(getWixDataTagId);
  const postListWidgetIsFeatured = useSelector((state) =>
    getAppSettingsValue({
      state,
      key: `style.booleans.${params.postListWidgetIsFeatured.key}`,
    }),
  );
  const queryLocale = useSelector(getQueryLocale);

  const isFiltered = Boolean(wixDataCategoryId || wixDataTagId || postListWidgetIsFeatured);

  return {
    isMultilingual: Boolean(queryLocale),
    showFilterWarning: isEditorSegment && isFiltered,
    showUnifiedStateText: isEditor,
  };
};

export default NoPosts;
