import React from 'react';
import { connect } from '../components/runtime-context';
import { getIsRTL } from '../store/basic-params/basic-params-selectors';

const forDirection = (ComponentLtr, ComponentRtl) => {
  const ForDirection = ({ isRTL, ...props }) => {
    const Component = isRTL ? ComponentRtl : ComponentLtr;
    return <Component {...props} />;
  };

  const mapRuntimeToProps = (state) => ({
    isRTL: getIsRTL(state),
  });

  return connect(mapRuntimeToProps)(ForDirection);
};

export default forDirection;
