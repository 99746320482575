import {
  AUTHOR_INFO_TYPE_NAME,
  hasTPACommentsSettings,
  getTPASettingsIsCommentsEnabled,
  type Section,
} from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';
import {
  getIsViewCountEnabled,
  getIsCommentCountEnabled,
  getIsLikeCountEnabled,
  getIsAuthorNameEnabled,
  getIsPostUpdatedDateEnabled,
  getIsCommentsEnabled,
  getIsCategoryLabelsEnabled,
  getIsPostTitleEnabled,
  getIsPostPublishDateEnabled,
  getIsReadingTimeEnabled,
  getIsPostDescriptionEnabled,
  getAuthorInfoType,
  getIsAuthorPictureEnabled,
  getIsMoreOptionsMenuEnabled,
  getIsRelatedPostsEnabled,
  getRelatedPostsLabel,
  getIsCategoryLabelEnabled,
  getIsPostRatingEnabled,
  getIsTagsEnabled,
} from '../selectors/app-settings-selectors';
import { getIsPostInPreview } from '../selectors/is-post-in-preview';
import { getMainCategory } from '../store/categories/categories-selectors';
import { type NormalizedPost } from '../types';

export type UseFeedMetadataSettings = {
  showComments: boolean;
  showViewCount: boolean;
  showCommentCount: boolean;
  showLikeCount: boolean;
  showAuthorName: boolean;
  showAuthorPicture: boolean;
  showPostUpdatedDate: boolean;
  showCategoryLabels: boolean;
  showPostTitle: boolean;
  showPublishDate: boolean;
  showReadingTime: boolean;
  showPostDescription: boolean;
  showMoreOptionsMenu: boolean;
  showCategoryLabel: boolean;
  /** Alias for: showViewCount || showCommentCount || showLikeCount */
  isMetadataFooterVisible: boolean;
  /** Alias for: showAuthorName || showPublishDate || showReadingTime */
  isMetadataHeaderVisible: boolean;
  showRelatedPosts: boolean;
  relatedPostsLabelKey: string | undefined;
  showPostRating: boolean;
  showTags: boolean;
};

type UseFeedMetadataSettingsParams = {
  post?: NormalizedPost;
  section?: Section;
};

export const useFeedMetadataSettings = (
  params?: UseFeedMetadataSettingsParams,
): UseFeedMetadataSettings => {
  const tpaCommentsSettings = useSelector(hasTPACommentsSettings);
  const isTPACommentsEnabled = useSelector(getTPASettingsIsCommentsEnabled);
  const isCommentsEnabled = useSelector(getIsCommentsEnabled);
  const isPostInPreview = useSelector(getIsPostInPreview);

  const showComments =
    !isPostInPreview && (tpaCommentsSettings ? isTPACommentsEnabled : isCommentsEnabled);

  const isRelatedPostsEnabled = useSelector(
    (state) => !isPostInPreview && getIsRelatedPostsEnabled(state),
  );
  const relatedPostsLabelKey = useSelector((state) =>
    !isPostInPreview ? getRelatedPostsLabel(state) : undefined,
  );
  const showViewCount = useSelector((state) => !isPostInPreview && getIsViewCountEnabled(state));
  const showCommentCount = useSelector((state) => showComments && getIsCommentCountEnabled(state));
  const showLikeCount = useSelector((state) => !isPostInPreview && getIsLikeCountEnabled(state));
  const showAuthorName = useSelector(getIsAuthorNameEnabled);
  const authorInfoType = useSelector(getAuthorInfoType);
  const showPostUpdatedDate = useSelector(getIsPostUpdatedDateEnabled);
  const showCategoryLabels = useSelector(getIsCategoryLabelsEnabled);
  const showPostTitle = useSelector(getIsPostTitleEnabled);
  const showPublishDate = useSelector(getIsPostPublishDateEnabled);
  const showReadingTime = useSelector(getIsReadingTimeEnabled);
  const showPostDescription = useSelector((state) =>
    getIsPostDescriptionEnabled(state, params?.section),
  );
  const oldShowAuthorPictureValue = authorInfoType !== AUTHOR_INFO_TYPE_NAME;
  const showAuthorPicture = useSelector((state) =>
    getIsAuthorPictureEnabled(state, oldShowAuthorPictureValue),
  );
  const showMoreOptionsMenu = useSelector(getIsMoreOptionsMenuEnabled);
  const showCategoryLabel = useSelector((state) =>
    getMainCategory(state, params?.post) ? getIsCategoryLabelEnabled(state) : false,
  );
  const showPostRating = useSelector(getIsPostRatingEnabled);
  const showTags = useSelector(getIsTagsEnabled);

  return {
    showComments,
    showViewCount,
    showCommentCount,
    showLikeCount,
    showAuthorName,
    showAuthorPicture,
    showPostUpdatedDate,
    showCategoryLabels,
    showPostTitle,
    showPublishDate,
    showReadingTime,
    showPostDescription,
    showMoreOptionsMenu,
    showCategoryLabel,
    isMetadataFooterVisible:
      !isPostInPreview && (showViewCount || showCommentCount || showLikeCount),
    isMetadataHeaderVisible: showAuthorName || showPublishDate || showReadingTime,
    showRelatedPosts: isRelatedPostsEnabled,
    relatedPostsLabelKey,
    showPostRating,
    showTags,
  };
};
