import { get } from 'lodash';
import {
  getCardBorderWidthPath,
  isLayoutTextOnImage,
  type LayoutType,
  type Section,
} from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';
import { getAppSettings } from '../selectors/app-settings-base-selectors';
import useLayoutProps, { type UseLayoutPropsParams } from './use-layout-props';

export type UseCardBorderWidthOwnProps = UseLayoutPropsParams;
const DEFAULT_WIDTH = 1;

const useCardBorderWidth = (props?: UseCardBorderWidthOwnProps) => {
  const layoutProps = useLayoutProps(props);
  const isTextOnImage = isLayoutTextOnImage(layoutProps.layoutType as LayoutType);

  const cardBorderWidthPath = getCardBorderWidthPath(
    layoutProps.layoutType as LayoutType,
    layoutProps.section as Section,
  );

  const appSettings = useSelector(getAppSettings);
  const borderWidth = get(appSettings, cardBorderWidthPath, DEFAULT_WIDTH) as number;
  return {
    ...layoutProps,
    borderWidth: isTextOnImage ? 0 : borderWidth,
  };
};

export default useCardBorderWidth;
