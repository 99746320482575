import React from 'react';
import classNames from 'classnames';
import styles from './loader.scss';

type Props = {
  bubbleClassName?: string;
  dataHook?: string;
};

const Loader: React.FC<Props> = ({ bubbleClassName = 'blog-text-background-color', dataHook }) => {
  const classname = classNames(styles.dot, bubbleClassName);
  return (
    <div className={styles.container} data-hook={dataHook}>
      <span className={classname} />
      <span className={classname} />
      <span className={classname} />
    </div>
  );
};

export default Loader;
