import React from 'react';
import classNames from 'classnames';
import { resolveId } from '@wix/communities-blog-client-common';
import { type LayoutConfigItemComponentProps } from '@app/external/feed-page/components/post-list/layout-config-component-props';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import useFontClassName from '../../hooks/use-font-class-name';
import useLayoutColorClasses from '../../hooks/use-layout-color-classes';
import { getIsMetadataLoaded } from '../../store/is-loaded/is-loaded-selectors';
import { LikeButtonWithCount } from '../like-button-with-count';
import { PostStats } from '../post-stats';
import { useSelector } from '../runtime-context';
import { SkeletonRenderer } from '../skeleton-renderer';
import styles from './post-footer.scss';

type Props = Pick<LayoutConfigItemComponentProps, 'post'> & {
  displayIcons?: boolean;
  className?: string;
  type?: LayoutConfigItemComponentProps['type'] | 'search';
  inPostPage?: boolean;
};

export const PostFooter = ({
  post,
  displayIcons = false,
  type,
  className,
  inPostPage = false,
}: Props) => {
  const { showViewCount, showCommentCount, showLikeCount } = useFeedMetadataSettings({ post });
  const { textColorClassName } = useLayoutColorClasses();
  const { contentFontClassName } = useFontClassName();

  const postId = resolveId(post);

  const isMetadataLoaded = useSelector((state) => {
    if (inPostPage) {
      return getIsMetadataLoaded(state);
    }
    return true;
  });

  return (
    <div
      data-hook="post-footer"
      className={classNames(
        styles.container,
        type ? styles[type] : undefined,
        contentFontClassName,
        textColorClassName,
        'post-footer',
        className,
      )}
    >
      {(showViewCount || showCommentCount) && (
        <div className={styles.leftButtons}>
          <PostStats
            postId={postId!}
            isMetadataLoaded={isMetadataLoaded}
            postCommentsDisabled={post.isCommentsDisabled}
            displayIcons={displayIcons}
            showViewCount={showViewCount}
            showCommentCount={showCommentCount}
          />
        </div>
      )}
      {showLikeCount && (
        <div className={styles.rightButtons}>
          <SkeletonRenderer isLoaded={isMetadataLoaded} width={40}>
            <LikeButtonWithCount
              key={postId}
              postId={postId!}
              className="post-footer__like-button"
            />
          </SkeletonRenderer>
        </div>
      )}
    </div>
  );
};
