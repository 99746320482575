import { type Options } from 'pro-gallery-lib';
import { getPostCover, resolveId } from '@wix/communities-blog-client-common';
import { type getPGEmptyCoverImage } from '../../services/post-list-pro-gallery';
import { type CoverImage, type NormalizedPost } from '../../types';
import { WIX_MEDIA_PREFIX } from './resizeMediaUrl';

const WIX_MEDIA_IMAGE_REGEX =
  /(?:https:\/\/static.wixstatic.com\/media\/)([\w\d.]+)(?:\/v\d\/(?:fit|fill)\/)w_(\d+)*(?:,|%2C)h_(\d+)/;

export const extractMetadataFromString = (url: string) => {
  const matches = url.match(WIX_MEDIA_IMAGE_REGEX);
  if (matches) {
    const [, filename, width, height] = matches;
    return {
      file_name: filename,
      width: +width,
      height: +height,
    };
  }

  return {
    file_name: '',
    height: 1,
    width: 10000,
  };
};

export const mapPostToPGItem = (
  post: NormalizedPost,
  hideCoverImage: boolean | undefined,
  emptyCoverImage: ReturnType<typeof getPGEmptyCoverImage>,
  fallbackSize: ReturnType<typeof getPGFallbackImageSize>,
) => {
  const itemId = resolveId(post);
  const alt = post?.media?.altText ?? post.title;
  const emptyItem = {
    itemId,
    metaData: { ...emptyCoverImage, alt },
    url: '',
  };

  const postCover: Partial<CoverImage> = getPostCover(post, '', '') ?? {};
  let { imageMetadata } = postCover;
  const { videoMetadata, shouldRender } = postCover;

  if (hideCoverImage || !shouldRender) {
    return emptyItem;
  }

  if (imageMetadata) {
    if (
      typeof imageMetadata === 'string' &&
      (imageMetadata as string).startsWith(WIX_MEDIA_PREFIX)
    ) {
      imageMetadata = extractMetadataFromString(imageMetadata);
    }

    return {
      itemId,
      metaData: {
        type: 'image',
        height: imageMetadata.height || fallbackSize.height,
        width: imageMetadata.width || fallbackSize.width,
        alt,
      },
      url: imageMetadata.id ?? imageMetadata.file_name,
    };
  } else if (videoMetadata) {
    const { height, width, thumbnail_height, thumbnail_width, thumbnail_url } = videoMetadata;
    const videoUrlEmpty = videoMetadata?.video_url === '';
    const videoUrl = videoUrlEmpty ? thumbnail_url : videoMetadata?.video_url;

    return {
      itemId,
      metaData: {
        type: videoUrlEmpty ? 'image' : 'video',
        height: height || thumbnail_height || fallbackSize.height,
        width: width || thumbnail_width || fallbackSize.width,
        poster: {
          url: thumbnail_url,
          height: thumbnail_height,
          width: thumbnail_width,
        },
        alt,
        videoUrl,
      },
      url: videoUrl,
    };
  } else if (post?.media?.embedMedia) {
    if (post?.media?.embedMedia?.video) {
      const videoUrlEmpty = post?.media?.embedMedia?.video?.url === '';
      const videoUrl = videoUrlEmpty
        ? post?.media?.embedMedia?.thumbnail?.url
        : post?.media?.embedMedia?.video?.url;

      return {
        itemId,
        metaData: {
          type: videoUrlEmpty ? 'image' : 'video',
          height: post?.media?.embedMedia?.video?.height,
          width: post?.media?.embedMedia?.video?.width,
          poster: {
            url: post?.media?.embedMedia?.thumbnail?.url,
            height: post?.media?.embedMedia?.thumbnail?.height || fallbackSize.height,
            width: post?.media?.embedMedia?.thumbnail?.width || fallbackSize.width,
          },
          alt,
          videoUrl,
        },
        url: videoUrl,
      };
    } else {
      return {
        itemId,
        metaData: {
          type: 'image',
          height: post?.media?.embedMedia?.thumbnail?.height || fallbackSize.height,
          width: post?.media?.embedMedia?.thumbnail?.width || fallbackSize.width,
          alt,
        },
        url: post?.media?.embedMedia?.thumbnail?.url,
      };
    }
  }

  return emptyItem;
};

export const getPGFallbackImageSize = (options: Options) => {
  const cubeRatio: number = options.cubeRatio || 1;
  const takenSpaceInPercent: number = options.isVertical ? 0 : options.textBoxWidthPercent ?? 50;

  const imagesPerRow: number = options.numberOfImagesPerRow ?? 1;
  const postSizeInPx: number = options.gallerySizePx ?? 0;

  const columnWidth = postSizeInPx / imagesPerRow;

  const remainingWidthForImage = columnWidth - columnWidth * takenSpaceInPercent * 0.01;
  const remainingHeightForImage = remainingWidthForImage / cubeRatio;

  return {
    width: remainingWidthForImage,
    height: remainingHeightForImage,
  };
};
