import React from 'react';
import { SECTION_BLOG_POST_PAGE } from '@wix/communities-blog-client-common';
import BaseLink from './base-link';

type BaseLinkProps = React.ComponentProps<typeof BaseLink>;

type Props = {
  postLink: BaseLinkProps['href'] | null;
  postPath: BaseLinkProps['path'];
} & Omit<BaseLinkProps, 'href' | 'path' | 'sectionId'>;

const PostLink: React.FC<Props> = ({ postLink, postPath, children, ...rest }) => {
  return (
    <BaseLink
      {...rest}
      href={postLink ?? undefined}
      path={postPath}
      sectionId={SECTION_BLOG_POST_PAGE}
    >
      {children}
    </BaseLink>
  );
};

export default PostLink;
