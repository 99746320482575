import React, { type FC, type CSSProperties } from 'react';
import classNames from 'classnames';
import type { LayoutName } from '@wix/communities-blog-client-common';
import { usePostTitleTag } from '../../context/post-title-tag-context';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import { getPostListMobileLayoutNameForTitleFontColor } from '../../selectors/mobile-settings-selectors';
import { getFeedColorClassName } from '../../services/layout-config';
import DotDotDot from '../dotdotdot';
import { useSelector } from '../runtime-context';
import styles from './post-list-item-pro-gallery-title.scss';

type PostListItemProGalleryTitleProps = {
  style?: CSSProperties;
  lineCount?: number;
  titleLineCount?: number;
  title?: string;
  type?: LayoutName;
  showCategoryLabel: boolean;
  isHoverDisabled?: boolean;
};

export const PostListItemProGalleryTitle: FC<PostListItemProGalleryTitleProps> = ({
  style,
  lineCount,
  titleLineCount,
  title,
  type: layoutName,
  showCategoryLabel,
  isHoverDisabled,
}) => {
  const { tagName: Component } = usePostTitleTag();
  const mobileLayoutName = useSelector(getPostListMobileLayoutNameForTitleFontColor);
  const { getPostClassName } = useIsFeedDesignEnabled();
  const titleClassName = classNames(
    getPostClassName(
      getFeedColorClassName(mobileLayoutName || layoutName, 'title-color'),
      'title-font',
    ),
  );

  const titleStyle = React.useMemo(
    () => ({
      WebkitLineClamp: titleLineCount,
    }),
    [titleLineCount],
  );

  const className = classNames(
    styles.title,
    {
      [styles.titleHover]: !isHoverDisabled,
      'blog-hover-container-element-color': !isHoverDisabled,
    },
    layoutName && styles[layoutName],
    'post-title',
    titleClassName,
    showCategoryLabel && styles.withCategoryLabel,
  );

  return (
    <div className={className} style={style} data-hook="post-title">
      {titleLineCount ? (
        <Component
          className={classNames(
            style?.fontSize ? styles.inheritFont : titleClassName,
            styles.clamp,
          )}
          style={titleStyle}
        >
          {title}
        </Component>
      ) : (
        <DotDotDot
          useExactLineHeight={true}
          clamp="auto"
          maxLineCount={lineCount}
          className={style?.fontSize ? styles.inheritFont : titleClassName}
          as={Component}
        >
          {title}
        </DotDotDot>
      )}
    </div>
  );
};

export default PostListItemProGalleryTitle;
