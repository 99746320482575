import React from 'react';
import classNames from 'classnames';
import { type PostAction, resolveId, type LayoutName } from '@wix/communities-blog-client-common';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import { type NormalizedPost } from '../../types';
import { MoreButton } from '../more-button';
import { importPostActions } from '../post-actions';
import PostHeaderIcons from '../post-header-icons';
import styles from './post-list-item-header.scss';

type Props = {
  moreButtonClass?: string;
  moreButtonIcon?: React.ReactNode;
  post: NormalizedPost;
  showHeaderIcons?: boolean;
  showMoreButton?: boolean;
  type: LayoutName | 'search' | undefined;
  visibleActions?: PostAction[];
};

const PostListItemHeaderOptions: React.FC<Props> = ({
  moreButtonClass,
  moreButtonIcon,
  post,
  showHeaderIcons = true,
  showMoreButton = true,
  type,
  visibleActions,
}) => {
  const moreButtonId = `more-button-${resolveId(post)}`;
  const buttonProps = {
    id: moreButtonId,
    ...(moreButtonIcon ? { icon: moreButtonIcon } : {}),
  };

  return (
    <div className={classNames(styles.postOptions, alignmentStyles.options)}>
      {showHeaderIcons ? (
        <PostHeaderIcons post={post} className={styles.icons} iconClassName="blog-button-fill" />
      ) : (
        <div className={styles.icons} />
      )}
      <div
        className={classNames(
          styles.moreButton,
          type ? styles[type] : undefined,
          'blog-more-icon-fill',
          moreButtonClass,
        )}
      >
        {showMoreButton && (
          <MoreButton {...buttonProps}>
            {async () => {
              const PostActions = await importPostActions();
              return <PostActions post={post} visibleActions={visibleActions} />;
            }}
          </MoreButton>
        )}
      </div>
    </div>
  );
};

export default PostListItemHeaderOptions;
