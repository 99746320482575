import React from 'react';
import type { PaginationState } from '../hooks/use-pagination-state';

type PositionProps = {
  state: PaginationState;
  className?: string;
};

const Position: React.FC<PositionProps> = ({ className, state }: PositionProps) => (
  <div className={className}>
    {state.currentPage}/{state.lastPage}
  </div>
);

export default Position;
