import React, { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { Provider } from 'react-redux';
import { createStore, type Store } from 'redux';
import { type IControllerConfig, type IHostProps } from '@wix/yoshi-flow-editor';
import { createAction } from '@wix/communities-blog-client-common';
import { createPromisifiedActionsService } from '../../actions-promisifier/create-promisified-actions-service';
import { withPromisifiedOpenModal } from '../../modals/framework/store/modal-actions';
import { getUpdatedState } from '../../services/state-optimizer/change-detector';
import { isDebug, isProduction, isEditor } from '../../store/basic-params/basic-params-selectors';
import { type AppState } from '../../types';

const setState = createAction('root/SET_STATE', (payload: AppState) => payload);
const setHost = createAction('root/SET_HOST', (payload: IHostProps) => payload);

type AnyAction = ReturnType<typeof setState> | ReturnType<typeof setHost>;

type RootState = {
  state?: Record<string, any>;
  host?: IHostProps;
  actions?: Record<string, any>;
  workerReady?: Promise<void>;
};

const root = (state: RootState = {}, action: AnyAction): RootState => {
  switch (action.type) {
    case setState.type:
      return { ...state, state: action.payload };
    case setHost.type:
      return { ...state, host: action.payload };
    default:
      return state;
  }
};

/** Passed via controlled with `setProps()` */
export type WithReduxStoreOwnProps = {
  state: Record<string, any>;
  actions: Record<string, any>;
  actionsPromisified: Record<string, any>;
  stateVersions?: Record<string, number>;
  controllerId?: number;
  isSSR?: boolean;
};

type PlatformProps = {
  host: IHostProps;
};

type CommonProps = {
  isRTL?: boolean;
  isMobile?: boolean;
  publicData?: IControllerConfig['publicData'];
  stylesParams?: IControllerConfig['style']['styleParams'];
  usesCssPerBreakpoint?: boolean;
};

export const withReduxStore = <T extends WithReduxStoreOwnProps & PlatformProps & CommonProps>(
  AppComponent: React.ComponentType<T>,
) => {
  const WithReduxStore: React.FC<T> = (props) => {
    const { state, stateVersions, actions, actionsPromisified, host, controllerId, isSSR } = props;
    const storeRef = useRef<Store | null>(null);
    const stateVersionsRef = useRef<Record<string, number> | undefined>(stateVersions);
    const controllerIdRef = useRef<number | undefined>(controllerId);
    const promisifiedActionsService = useRef(createPromisifiedActionsService());
    const workerReadyRef = useRef<Promise<void> | undefined>(undefined);
    const markWorkerAsReadyRef = useRef<() => void>(() => {});

    const patchState = React.useCallback(
      (patchedStated: Record<string, any>, widgetHost: IHostProps) => {
        state.appSettings = {
          ...patchedStated.appSettings,
          colorPresets: widgetHost.style.siteColors,
          textPresets: widgetHost.style.siteTextPresets,
          style: widgetHost.style.styleParams,
        };
      },
      [state],
    );

    useEffect(() => {
      if (storeRef.current) {
        const currentState = storeRef.current.getState().state;
        const currentStateVersions = stateVersionsRef.current;

        const updatedState = getUpdatedState({
          currentState,
          currentVersions: currentStateVersions,
          newState: state,
          newVersions: stateVersions,
          currentControllerId: controllerIdRef.current,
          newControllerId: controllerId,
        });

        stateVersionsRef.current = stateVersions;

        if (isEditor(updatedState)) {
          patchState(updatedState, host);
          if (!isEqual(updatedState, currentState)) {
            storeRef.current.dispatch(setState(updatedState));
          }
          storeRef.current.dispatch(setHost(host));
        } else {
          if (updatedState.appSettings && !updatedState.appSettings.colorPresets) {
            patchState(updatedState, host);
          }

          if (!isEqual(updatedState, currentState)) {
            storeRef.current.dispatch(setState(updatedState));
          }
        }
        promisifiedActionsService.current.resolvePromisifiedActions(updatedState);
      }
    }, [state, stateVersions, host, controllerId, patchState]);

    useEffect(() => {
      if (storeRef.current) {
        if (controllerIdRef.current !== controllerId) {
          controllerIdRef.current = controllerId;
        }
      }
    }, [controllerId]);

    useEffect(() => {
      if (markWorkerAsReadyRef.current) {
        markWorkerAsReadyRef.current();
      }
    }, [isSSR]);

    const getWorkerReady = () => {
      workerReadyRef.current ||= new Promise<void>((resolve) => {
        if (!isSSR) {
          resolve();
          markWorkerAsReadyRef.current = () => {};
        } else {
          markWorkerAsReadyRef.current = resolve;
        }
      });
      return workerReadyRef.current;
    };

    if (!storeRef.current) {
      const connectedActionsPromisified = Object.keys(actionsPromisified).reduce(
        (wrapped, actionName) => {
          wrapped[actionName] = promisifiedActionsService.current.usePromisifiedAction(
            actionsPromisified[actionName],
            state,
          );
          return wrapped;
        },
        {} as Record<string, () => Promise<any>>,
      );

      stateVersionsRef.current = stateVersions;
      patchState(state, host);
      storeRef.current = createStore(root, {
        state,
        actions: {
          ...actions,
          ...connectedActionsPromisified,
          openModal: withPromisifiedOpenModal(actions.openModal),
        },
        host,
        workerReady: getWorkerReady(),
      });
    }

    if (
      typeof jest === 'undefined' &&
      (isDebug(storeRef.current.getState().state) ||
        !isProduction(storeRef.current.getState().state))
    ) {
      console.log('AppRoot', props);
    }

    return (
      <Provider store={storeRef.current}>
        <AppComponent {...props} />
      </Provider>
    );
  };

  return WithReduxStore;
};
