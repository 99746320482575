import {
  CATEGORY_LABEL_TEXT_MOBILE_DESIGN_PARAMS,
  CATEGORY_LABEL_LAYOUTS,
  CATEGORY_LABEL_LAYOUT_PATH,
  CATEGORY_LABEL_TEXT_DESIGN_PARAMS,
  CATEGORY_LABEL_BUTTON_DESIGN_PARAMS,
  CATEGORY_LABEL_LAYOUT_MOBILE_PATH,
  CATEGORY_LABEL_BUTTON_MOBILE_DESIGN_PARAMS,
  CATEGORY_LABEL_MOBILE_DESIGN_TOGGLE,
} from '@wix/communities-blog-client-common';

import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getIsMobileSettingEnabled } from '../../common/selectors/mobile-settings-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';

export const shouldUseMobileSettings = (state) =>
  getIsMobile(state) &&
  getIsMobileSettingEnabled(state, CATEGORY_LABEL_MOBILE_DESIGN_TOGGLE.appSettingsPath);

const getCategoryLabelLayout = (state) =>
  getAppSettingsValue({
    state,
    key: shouldUseMobileSettings(state)
      ? CATEGORY_LABEL_LAYOUT_MOBILE_PATH
      : CATEGORY_LABEL_LAYOUT_PATH,
    fallback: CATEGORY_LABEL_LAYOUTS.text,
  });

export const getIsCategoryLabelLayoutText = (state) =>
  getCategoryLabelLayout(state) === CATEGORY_LABEL_LAYOUTS.text;

const getCategoryLabelParamsByDeviceType = (state) => {
  const useMobileSettings = shouldUseMobileSettings(state);
  const categoryLabelLayout = getCategoryLabelLayout(state);

  return {
    [CATEGORY_LABEL_LAYOUTS.text]: useMobileSettings
      ? CATEGORY_LABEL_TEXT_MOBILE_DESIGN_PARAMS
      : CATEGORY_LABEL_TEXT_DESIGN_PARAMS,
    [CATEGORY_LABEL_LAYOUTS.button]: useMobileSettings
      ? CATEGORY_LABEL_BUTTON_MOBILE_DESIGN_PARAMS
      : CATEGORY_LABEL_BUTTON_DESIGN_PARAMS,
  }[categoryLabelLayout];
};

const createCategoryLabelSettingsSelector = (param, defaultValueName) => (state) => {
  const params = getCategoryLabelParamsByDeviceType(state);
  return getAppSettingsValue({
    state,
    key: params[param].appSettingsPath,
    fallback: params[param][defaultValueName],
  });
};

export const getCategoryLabelTextFont = (state) => {
  const { textFont } = getCategoryLabelParamsByDeviceType(state);
  const font = getAppSettingsValue({ state, key: textFont.appSettingsPath });
  return (
    font || {
      size: textFont.defaultSize,
    }
  );
};

export const getCategoryLabelVerticalPadding = createCategoryLabelSettingsSelector(
  'verticalPadding',
  'defaultPadding',
);
export const getCategoryLabelBorderWidth = createCategoryLabelSettingsSelector(
  'borderWidth',
  'defaultWidth',
);
export const getIsCategoryLabelUppercase = createCategoryLabelSettingsSelector(
  'uppercase',
  'defaultValue',
);
