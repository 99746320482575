import React from 'react';
import classNames from 'classnames';
import { type LayoutName } from '@wix/communities-blog-client-common';
import ProfileLink from '../../../common/components/link/profile-link';
import UserName from '../../../common/components/user-name';
import useIsFeedDesignEnabled from '../../../common/hooks/use-is-feed-design-enabled';
import useLayoutColorClasses from '../../../common/hooks/use-layout-color-classes';
import { getFeedColorClassName } from '../../../common/services/layout-config';
import { type NormalizedPost } from '../../../common/types';
import PostMetadata from '../post-metadata';
import styles from './author-with-post-details.scss';

interface Props {
  post: NormalizedPost;
  type?: LayoutName | 'search';
  showAuthorName?: boolean;
  showPublishDate?: boolean;
  showReadingTime?: boolean;
  className?: string;
}

const AuthorWithPostDetails: React.FC<Props> = ({
  post,
  type,
  showAuthorName = true,
  showPublishDate = true,
  showReadingTime = true,
  className,
}) => {
  const layoutColorClasses = useLayoutColorClasses();
  const { applyFeedDesign, getPostClassName } = useIsFeedDesignEnabled();

  const userNameClassNames = classNames(
    styles.userName,
    !applyFeedDesign && ['blog-link-hover-color', layoutColorClasses.textColorClassName],
    getPostClassName(
      getFeedColorClassName(type as LayoutName, 'description-color'),
      'description-font',
      'link-hashtag-hover-color',
    ),
  );

  return (
    <div className={classNames([styles.container, type ? styles[type] : undefined, className])}>
      {showAuthorName && (
        <ProfileLink user={post.owner} className={styles.profileLink}>
          <UserName className={userNameClassNames} name={post.owner?.name ?? '-'} />
        </ProfileLink>
      )}
      {(showPublishDate || showReadingTime) && (
        <PostMetadata
          date={post.firstPublishedDate || new Date()}
          readTime={post.timeToRead}
          isShortReadTimeLabel={false}
          className={classNames(
            getPostClassName(
              'description-font',
              getFeedColorClassName(type as LayoutName, 'description-color'),
            ),
          )}
          showPublishDate={showPublishDate}
          showReadingTime={showReadingTime}
        />
      )}
    </div>
  );
};

export default AuthorWithPostDetails;
