import { get } from 'lodash';
import { createSelector } from 'reselect';
import {
  VIEW_MODE_SITE,
  VIEW_MODE_EDITOR,
  VIEW_MODE_PREVIEW,
  VIEW_MODE_ONBOARDING,
} from '@wix/communities-blog-client-common';

export const getBasicParams = (state) => state.basicParams || {};

export const getViewMode = (state) => state.basicParams?.viewMode;

const getFormFactor = (state) => state.basicParams?.formFactor;

export const getInstance = createSelector([getBasicParams], ({ instance }) => instance);

/** Same as using `useEnvironment().language` */
export const getLanguage = (state) => state.basicParams?.language;

export const getUrl = createSelector([getBasicParams], ({ url }) => url);

/** Same as using `useEnvironment().isSSR` */
export const isSSR = createSelector([getBasicParams], ({ isSSR }) => isSSR);

/** Same as using `useEnvironment().isRTL` */
export const getIsRTL = createSelector([getBasicParams], ({ isRTL }) => isRTL);

export const isDebug = createSelector([getBasicParams], ({ isDebug }) => isDebug);

export const isProduction = createSelector(
  (state) => getBasicParams(state),
  ({ isProduction }) => isProduction,
);

export const getCommentId = createSelector([getBasicParams], ({ commentId }) => commentId);

export const getIsRendered = createSelector([getBasicParams], ({ isRendered }) => isRendered);

/** Same as using `useEnvironment().isViewer` */
export const isSite = createSelector([getViewMode], (viewMode) => viewMode === VIEW_MODE_SITE);

/** Same as using `useEnvironment().isEditor` */
export const isEditor = createSelector([getViewMode], (viewMode) => viewMode === VIEW_MODE_EDITOR);

/** Same as using `useEnvironment().isPreview` */
export const isPreview = createSelector(
  [getViewMode],
  (viewMode) => viewMode === VIEW_MODE_PREVIEW,
);

export const getIsEditorSegment = createSelector(
  [getViewMode],
  ({ editor, preview }) => editor || preview,
);

export const isAdi = createSelector([getViewMode], (viewMode) => viewMode === VIEW_MODE_ONBOARDING);

export const isSeo = createSelector([getBasicParams], ({ isSeo }) => isSeo);

/**
 * Not same as using `useEnvironment().isMobile`. Always `false` for non-mobile-friendly sites.
 * @see render-not-mobile-friendly.spec.ts
 **/
export const getIsMobile = createSelector([getFormFactor], (formFactor) => formFactor === 'Mobile');

export const getIsDesktop = createSelector(
  [getFormFactor],
  (formFactor) => formFactor !== 'Mobile',
);

export const getIsForumLoadedOnInitialPage = createSelector(
  [getBasicParams],
  ({ biPageNumber }) => biPageNumber !== undefined && biPageNumber === 1,
);

const getCurrentSitePage = (state) => state.basicParams.currentPage;

export const getCurrentSitePageTitle = createSelector([getCurrentSitePage], (currentPage) =>
  get(currentPage, 'name', ''),
);

export const getTimezone = createSelector([getBasicParams], ({ timeZone }) => timeZone);
