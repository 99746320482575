import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getDisplayName from 'react-display-name';

export const withDirection = (Component) => {
  class WithDirection extends React.Component {
    static displayName = `withDirection(${getDisplayName(Component)})`;

    render() {
      return (
        <div dir={this.props.isRTL ? 'rtl' : undefined}>
          <Component {...this.props} />
        </div>
      );
    }
  }

  hoistNonReactStatics(WithDirection, Component);

  return WithDirection;
};
