import React from 'react';
import classNames from 'classnames';

import styles from './user-name.scss';

interface Props {
  name: string;
  className?: string;
}

const UserName: React.FC<Props> = ({ name, className }) => (
  <span
    title={name}
    className={classNames(styles.container, 'user-name', className)}
    data-hook="user-name"
  >
    {name}
  </span>
);

export default UserName;
