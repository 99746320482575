// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
import { type WidgetState } from './widget-state.type';

type SelectorFn<T> = (
  state: WidgetState['state'],
  host: WidgetState['host'],
  actions: WidgetState['actions'],
) => T;

export default <T>(cb: SelectorFn<T>) => {
  return useSelector((state: WidgetState) => cb(state.state, state.host, state.actions));
};
