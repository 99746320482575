import React, { useEffect, useMemo, useState } from 'react';
import type { GalleryProps } from 'pro-gallery-lib';
import { LayoutFixer } from '@wix/pro-gallery-layout-fixer';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { EXPERIMENT_USE_LAYOUT_FIXER } from '@wix/communities-blog-experiments';
import { isSeo as getIsSeo } from '../../store/basic-params/basic-params-selectors';
import { useResponsiveContext } from '../responsive-listener/responsive-context';
import { useSelector } from '../runtime-context';

type Props = {
  id: NonNullable<GalleryProps['id']>;
  items: NonNullable<GalleryProps['items']>;
  options: NonNullable<GalleryProps['options']>;
  children: (isPrerenderMode: boolean) => React.ReactNode;
};

export function PostListProGalleryLayoutFixer(props: Props) {
  const { rootWidth } = useResponsiveContext();
  const isSEO = useSelector(getIsSeo);

  const { dimensions } = useEnvironment();

  const nextIsDimensionsUnknown = useMemo(() => {
    const width = (dimensions.width as number | '') || rootWidth;
    const isWidthUnknown = !width || isNaN(width);

    return isWidthUnknown && !isSEO;
  }, [dimensions?.width, isSEO, rootWidth]);

  const [isDimensionsUnknown, setIsDimensionsUnknown] = useState(nextIsDimensionsUnknown);

  const { experiments } = useExperiments();

  const isPrerenderMode = useMemo(
    () => experiments.enabled(EXPERIMENT_USE_LAYOUT_FIXER) && isDimensionsUnknown,
    [experiments, isDimensionsUnknown],
  );

  useEffect(() => {
    if (isDimensionsUnknown !== nextIsDimensionsUnknown) {
      const timer = requestAnimationFrame(() => {
        setIsDimensionsUnknown(nextIsDimensionsUnknown);
      });

      return () => cancelAnimationFrame(timer);
    }
  }, [nextIsDimensionsUnknown, isDimensionsUnknown]);

/**
 * Checks if all items in the `props.items` array have an empty `url` property (don't have an image). In this case do not render the layout fixer.
 */
  const containsEmptyItems = props.items.every((item) => item.url === '');

  const showLayoutFixer = !containsEmptyItems && isPrerenderMode;

  return (
    <>
      {props.children(isPrerenderMode)}
      {showLayoutFixer ? (
        <LayoutFixer id={props.id} items={props.items} isPrerenderMode options={props.options} />
      ) : undefined}
    </>
  );
}
