import React, { useLayoutEffect } from 'react';
import classNames from 'classnames';
import { withResponsiveContext } from '../responsive-listener/responsive-context';
import { clamp } from './clamp';
import styles from './dotdotdot.scss';

type Props = {
  clamp: 'auto' | 'parent' | number;
  className?: string;
  useExactLineHeight?: boolean;
  maxLineCount?: number;
  as?: keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
};

const DotDotDot: React.FC<Props> = (props) => {
  const type = props.as ?? 'span';
  const rootRef = React.useRef<HTMLElement>(null);
  const Element = () =>
    React.createElement(
      type,
      { ref: rootRef, className: classNames(styles.root, props.className) },
      props.children,
    );

  useLayoutEffect(() => {
    if (!props.clamp || !rootRef.current) {
      return;
    }

    if (React.Children.count(props.children) > 1) {
      throw new Error('Please provide exactly one child to dotdotdot');
    }

    clamp(rootRef.current, {
      clamp: props.clamp,
      useExactLineHeight: props.useExactLineHeight,
      useNativeClamp: true,
      truncationChar: '\u2026',
    });
  }, [props]);

  return <Element />;
};

// `withResponsiveContext` is used to force re-rendering of the component when root width changes.
export default withResponsiveContext(DotDotDot) as any as React.FC<Props>;
