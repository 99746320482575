import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import styles from './post-header-icon.scss';

export const PostHeaderIcon = ({ IconComponent, className, iconColorClassName }) => (
  <div className={styles.iconWrapper}>
    <div className={classNames(styles.icon, className)}>
      <IconComponent className={classNames(iconColorClassName, className)} />
    </div>
  </div>
);

PostHeaderIcon.propTypes = {
  IconComponent: PropTypes.func.isRequired,
  className: PropTypes.string,
  iconColorClassName: PropTypes.string.isRequired,
};

export default withLayoutColorClasses(PostHeaderIcon);
