import {
  type PostAction,
  POST_ACTIONS,
  POST_ACTION_SHARE,
  POST_ACTION_SUBSCRIBE,
} from '@wix/communities-blog-client-common';
import { type NormalizedPost } from '../types';

export const getPostActions = ({
  actions = POST_ACTIONS,
  post,
  canSee,
  enableShare = true,
  enableSubscribe = true,
}: {
  actions?: PostAction[] | readonly PostAction[];
  post: NormalizedPost;
  canSee: (action: PostAction, type: 'post', post: NormalizedPost) => boolean;
  enableShare?: boolean;
  enableSubscribe?: boolean;
}): PostAction[] => {
  const hiddenActions: PostAction[] = [];

  if (!enableShare) {
    hiddenActions.push(POST_ACTION_SHARE);
  }

  if (!enableSubscribe) {
    hiddenActions.push(POST_ACTION_SUBSCRIBE);
  }

  const visibleActions = actions.filter(
    (action) => canSee(action, 'post', post) && !hiddenActions.includes(action),
  );

  return visibleActions;
};
