import React from 'react';

import styles from './popover-root.scss';

const PopoverRoot = () => {
  return <div className={styles.popoverRoot} id="new-blog-popover-root"></div>;
};

PopoverRoot.getPopoverRootElement = () => {
  return document.getElementById('new-blog-popover-root');
};

export default PopoverRoot;
