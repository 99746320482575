import React from 'react';
import classNames from 'classnames';
import { CommentCountCompact } from '../../../comments/components/comment-count-compact';
import { getViewCount, getCommentCount } from '../../store/post-counters/post-counters-selectors';
import { Counter } from '../counter';
import { type CounterProps } from '../counter/counter';
import { useSelector } from '../runtime-context';
import { SkeletonRenderer } from '../skeleton-renderer';
import ViewCount from '../view-count';
import ViewCountCompact from '../view-count-compact';
import styles from './post-stats.scss';

type Props = {
  postId: string;
  displayIcons: boolean;
  className?: string;
  isMetadataLoaded: boolean;
  showViewCount?: boolean;
  showCommentCount?: boolean;
  postCommentsDisabled: boolean;
};

export const PostStats = ({
  postId,
  displayIcons,
  className,
  isMetadataLoaded,
  showViewCount = true,
  showCommentCount = true,
  postCommentsDisabled,
}: Props) => {
  const viewCount = useSelector((state) => getViewCount(state, postId));
  const totalComments = useSelector((state) => getCommentCount(state, postId));

  const Views = displayIcons ? ViewCountCompact : ViewCount;
  const Count = displayIcons
    ? CommentCountCompact
    : (props: CounterProps) => <Counter i18nKey="comment-count.label" {...props} />;
  const showCommentStats = showCommentCount && (totalComments > 0 || !postCommentsDisabled);

  return (
    <div className={classNames(styles.container, 'post-stats', className)} data-hook="post-stats">
      {showViewCount && (
        <SkeletonRenderer isLoaded={isMetadataLoaded} width={30}>
          <Views count={viewCount} className={className} />
        </SkeletonRenderer>
      )}
      {showCommentStats && (
        <SkeletonRenderer isLoaded={isMetadataLoaded} width={30}>
          <Count showZero count={totalComments} className={className} />
        </SkeletonRenderer>
      )}
    </div>
  );
};
