import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

export type CounterProps = {
  count?: number;
  countFormatted?: string;
  i18nKey?: string;
  className?: string;
  showZero?: boolean;
  ariaHidden?: boolean;
  tabIndex?: number;
};

export const Counter: React.FC<CounterProps> = ({
  count = 0,
  countFormatted,
  i18nKey = 'counter.count',
  className,
  showZero = true,
  ariaHidden,
  tabIndex,
}) => {
  const { t } = useTranslation();

  return count > 0 || (showZero && count === 0) ? (
    <span aria-hidden={ariaHidden} className={className} tabIndex={tabIndex}>
      {t(i18nKey, { count, numberFormatted: countFormatted })}
    </span>
  ) : null;
};
