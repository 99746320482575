import { type ComponentType } from 'react';
import { withTranslation } from '@wix/yoshi-flow-editor';

/**
 * @deprecated
 * This is an alias for `withTranslation()` HOC that yoshi provides.
 *
 * Please use `withTranslation()` directly with `WithTranslation` for types.
 **/
const withTranslate = (Component: ComponentType) => withTranslation()(Component);

export default withTranslate;
