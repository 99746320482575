export type TagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

export const enum VideoPlayType {
  OnHover = 0,
  /** Available on desktop and Android devices only */
  Autoplay = 1,
  OnClick = 2,
}
export const enum VideoPlaybackSpeed {
  Quarter = 0,
  Half = 1,
  Normal = 2,
  OneAndQuarter = 3,
  OneAndHalf = 4,
  Two = 5,
}
export const enum CategoryLabelType {
  Text = 0,
  Button = 1,
}

export const enum CropType {
  Crop = 0,
  Fit = 1,
}

export const enum ImageRatio {
  /** Hidden on "Side by side" */
  SixteenByNine = 1,
  FourByThree = 2,
  /** Default on "Magazine" */
  OneByOne = 3,
  ThreeByFour = 4,
  /** Hidden on "Side by side" */
  NineBySixteen = 5,
  /** @deprecated hidden setting - used for old layouts to pro gallery migration */
  SixteenBySeven = 6,
}

export const enum ImageAlignment {
  End = 1,
  Start = 2,
}

export const enum HeightType {
  Manual = 0,
  Auto = 1,
}
