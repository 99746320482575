import { createSelector } from 'reselect';
import type { AppState } from '../types';

const getRouter = (state: AppState) => state.router;

export const getCurrentMatch = createSelector([getRouter], (router) => router?.currentMatch);

export const getPreviousMatch = createSelector(
  [getRouter],
  (router) => router?.previousMatches?.[1] ?? {},
);

export const getCurrentMatchPathname = createSelector(
  [getCurrentMatch],
  (currentMatch) => currentMatch?.pathname ?? '',
);

export const getCurrentMatchEncodedPathname = createSelector(
  [getCurrentMatch],
  (currentMatch) => currentMatch?.encodedPathname ?? '',
);

export const getIsInitialRoute = createSelector(
  [getRouter],
  (router) => router?.previousMatches.length === 0,
);

export const getRoute = createSelector([getCurrentMatch], (currentMatch) => currentMatch?.route);

export const getRouteParams = createSelector(
  [getCurrentMatch],
  (currentMatch) => currentMatch?.params,
);
