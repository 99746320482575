import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CounterCompact } from '../../../common/components/counter-compact';
import { CommentIcon } from '../../../common/components/icons/comment-icon';
import useLayoutColorClasses from '../../../common/hooks/use-layout-color-classes';

type CommentCountCompactProps = {
  count: number;
  countFormatted?: string;
  showZero: boolean;
  className?: string;
};

export const CommentCountCompact = ({
  count,
  countFormatted,
  showZero = false,
}: CommentCountCompactProps) => {
  const { t } = useTranslation();
  const { iconColorClassName } = useLayoutColorClasses();

  return (
    <CounterCompact
      dataHook="comment-count-compact"
      ariaHidden={true}
      count={count}
      countFormatted={countFormatted}
      showZero={showZero}
    >
      <CommentIcon
        title={t('comment-count.label', {
          count,
          numberFormatted: countFormatted,
        })}
        className={classNames(iconColorClassName, 'blog-link-hover-fill')}
      />
    </CounterCompact>
  );
};
