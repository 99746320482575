import React from 'react';
import type { Member } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import { USER_PRIVACY_STATUS_PRIVATE } from '@wix/communities-blog-client-common';
import NoopLink from '../components/link/noop-link';

type PropsWithUser = {
  user: Member | undefined;
};

function forUserPrivacyType<T extends PropsWithUser>(
  PublicUserComponent: React.FC<T>,
  PrivateUserComponent: React.FC<T> = NoopLink as React.FC<T>,
) {
  return (props: T) => {
    return props.user?.privacyStatus === USER_PRIVACY_STATUS_PRIVATE ? (
      <PrivateUserComponent {...props} />
    ) : (
      <PublicUserComponent {...props} />
    );
  };
}

export default forUserPrivacyType;
