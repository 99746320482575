const registry = {};

const getPromise = () => {
  let resolve;
  let reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return { promise, resolve, reject };
};

export const addModalToRegistry = () => {
  const correlationId = Date.now();
  const promise = getPromise();
  registry[correlationId] = promise;
  return { correlationId, promise: promise.promise };
};

export const resolveModalInRegistry = (correlationId, payload) => {
  if (registry[correlationId]) {
    registry[correlationId].resolve(payload);
    delete registry[correlationId];
  }
};
