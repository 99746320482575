import { createSelector } from 'reselect';
import type { AppState } from '../../types';

export type PostLikes = {
  liked: boolean;
  likeCount: number;
  isLikeInProgress: boolean;
};

export type PostLikesReducer = {
  [postId: string]: PostLikes;
};

export const getPostLikes = (state: AppState, postId: string) => state.postLikes[postId] || {};

export const getIsLiked = createSelector(getPostLikes, (postLikes) => postLikes.liked);

export const getIsLikeInProgress = createSelector(
  getPostLikes,
  (postLikes) => postLikes.isLikeInProgress,
);

export const getLikeCount = createSelector(getPostLikes, (postLikes) => postLikes.likeCount);
