import { isLayoutFullPost, SECTION_HOMEPAGE } from '@wix/communities-blog-client-common';
import { getIsPostDesignInFullPostEnabled } from './app-settings-selectors';
import { getLayoutType } from './layout-selectors';
import { getSection } from './section-selectors';

export const shouldApplyPostDesignInFeed = (state) => {
  const section = getSection(state);

  if (section !== SECTION_HOMEPAGE) {
    return false;
  }

  const layoutType = getLayoutType(state, section);
  return isLayoutFullPost(layoutType) && getIsPostDesignInFullPostEnabled(state);
};
