import React from 'react';
import memoizeOne from 'memoize-one';
import { getContentText } from '@wix/communities-universal/dist/src/services/editor-content-text';
import { POST_EXCERPT_MAX_LENGTH } from '@wix/communities-blog-client-common';
import type { NormalizedPost } from '../../types';
import DotDotDot from '../dotdotdot';
import styles from './post-list-item-pro-gallery-excerpt.scss';

const getDescriptionStyle = memoizeOne((descriptionLineCount) => ({
  WebkitLineClamp: descriptionLineCount,
}));

type Props = {
  post: NormalizedPost;
  lineCount?: number;
  descriptionLineCount?: number;
};

const PostListItemProGalleryExcerpt: React.FC<Props> = ({
  post: { content, excerpt },
  lineCount,
  descriptionLineCount,
}) => {
  const text: string = excerpt
    ? excerpt.slice(0, POST_EXCERPT_MAX_LENGTH)
    : getContentText(content).slice(0, POST_EXCERPT_MAX_LENGTH);

  return (
    <div className={styles.container}>
      {descriptionLineCount ? (
        <div className={styles.clamp} style={getDescriptionStyle(descriptionLineCount)}>
          {text}
        </div>
      ) : (
        <DotDotDot clamp="auto" maxLineCount={lineCount} className={styles.text}>
          {text}
        </DotDotDot>
      )}
    </div>
  );
};

export default PostListItemProGalleryExcerpt;
