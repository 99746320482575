import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { type LayoutName } from '@wix/communities-blog-client-common';
import useFontClassName from '../../hooks/use-font-class-name';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import usePostFontSize from '../../hooks/use-post-font-size';
import { getShouldUsePostListMobileDescriptionFontSize } from '../../selectors/mobile-settings-selectors';
import { type LineCount } from '../../services/layout-config';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import styles from '../../styles/post-list-item.scss';
import { type NormalizedPost } from '../../types';
import { useActions, useSelector } from '../runtime-context';
import PostListItemProGalleryExcerpt from './post-list-item-pro-gallery-excerpt';

type Props = {
  type: LayoutName | undefined;
  showMoreButton: boolean;
  withoutFooter: boolean;
  post: NormalizedPost;
  lineCount: LineCount | undefined;
  descriptionLineCount: number | undefined;
  withDescription: boolean;
  isHoverDisabled: boolean;
};

export const PostListItemProGalleryDescription = ({
  type,
  showMoreButton,
  withoutFooter,
  post,
  lineCount,
  descriptionLineCount,
  withDescription,
  isHoverDisabled,
}: Props) => {
  const actions = useActions();
  const { applyFeedDesign, getPostClassName } = useIsFeedDesignEnabled();
  const shouldUsePostListMobileDescriptionFontSize = useSelector(
    getShouldUsePostListMobileDescriptionFontSize,
  );
  const { contentFontClassNameWithStyle } = useFontClassName();
  const { postDescriptionFontSize, defaultPostDescriptionFontSize } = usePostFontSize();
  const layoutTypeClassName = type ? styles[type] : undefined;

  const excerptLinkClassName = useMemo(
    () =>
      classNames(
        styles.excerptLink,
        styles.textWrapper,
        styles.proGallery,
        layoutTypeClassName,
        showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
      ),
    [layoutTypeClassName, showMoreButton],
  );

  const contentClassName = useMemo(
    () =>
      classNames(
        styles.content,
        layoutTypeClassName,
        withoutFooter && styles.withoutFooter,
        contentFontClassNameWithStyle,
        getPostClassName('description-style-font'),
        alignmentStyles.content,
      ),
    [layoutTypeClassName, withoutFooter, contentFontClassNameWithStyle, getPostClassName],
  );

  const styleFontSizeContent = useMemo(
    () => ({
      fontSize:
        !applyFeedDesign || shouldUsePostListMobileDescriptionFontSize
          ? postDescriptionFontSize
          : defaultPostDescriptionFontSize,
    }),
    [
      applyFeedDesign,
      shouldUsePostListMobileDescriptionFontSize,
      postDescriptionFontSize,
      defaultPostDescriptionFontSize,
    ],
  );

  const renderPostDescription = useCallback(
    () => (
      <div
        style={styleFontSizeContent}
        className={classNames(contentClassName, alignmentStyles.textAlign)}
        data-hook="post-description"
      >
        <PostListItemProGalleryExcerpt
          post={post}
          lineCount={lineCount?.description}
          descriptionLineCount={descriptionLineCount}
        />
      </div>
    ),
    [styleFontSizeContent, contentClassName, post, lineCount?.description, descriptionLineCount],
  );

  return withDescription && (lineCount?.description || descriptionLineCount) ? (
    isHoverDisabled ? (
      renderPostDescription()
    ) : (
      <div
        className={excerptLinkClassName}
        onClick={() => actions.navigateProGalleryWithinPostPage(post?.slug!)}
      >
        {renderPostDescription()}
      </div>
    )
  ) : null;
};
