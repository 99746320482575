import React from 'react';

export const ArrowLeftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="12"
    viewBox="0 0 7 12"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="M 1.503579,6.000003 6.933844,0.824205 c 0.08821,-0.08407 0.08821,-0.220384 0,-0.304458 L 6.454703,0.063059 c -0.08821,-0.08407 -0.23122,-0.08407 -0.319427,0 L 0.066156,5.847774 C 0.022052,5.889811 0,5.944907 0,6.000003 c 0,0.0551 0.02205,0.110193 0.06616,0.152229 l 6.06912,5.784715 c 0.08821,0.08407 0.23122,0.08407 0.319427,0 l 0.479141,-0.456688 c 0.08821,-0.08407 0.08821,-0.220383 0,-0.304459 z" />
  </svg>
);
