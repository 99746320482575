import React from 'react';
import classNames from 'classnames';
import { type LayoutName } from '@wix/communities-blog-client-common';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import styles from '../../styles/post-list-item.scss';
import { type NormalizedPost } from '../../types';
import PostListItemCategoryLabel from '../post-list-item-category-label';

type Props = {
  post: NormalizedPost;
  type: LayoutName | undefined;
};

export const PostListItemProGalleryCategoryLabel = ({ post, type }: Props) => {
  const feedMetadataSettings = useFeedMetadataSettings({ post });

  return feedMetadataSettings.showCategoryLabel ? (
    <PostListItemCategoryLabel
      className={classNames(alignmentStyles.categoryLabel, styles.categoryLabelWrapper)}
      post={post}
      postListLayout={type}
    />
  ) : null;
};
