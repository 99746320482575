import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import {
  feedWidgetStyleParams,
  getLayoutName,
  PostListWidgetSliderArrowsPosition,
  postListWidgetStyleParams,
  postListWidgetStyleParamsByDevice,
  POST_LIST_WIDGET_DEFAULTS,
  SECTION_POST_LIST,
} from '@wix/communities-blog-client-common';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import { useActions, useSelector } from '../../../common/components/runtime-context';
import Pagination from '../../../common/containers/pagination/pagination';

import useDeviceType from '../../../common/hooks/use-device-type';
import {
  getAppSettingsValue,
  getPostListPaginationEnabled,
} from '../../../common/selectors/app-settings-base-selectors';
import {
  getUseMobileDisplaySettings,
  getUseMobileLayoutSettings,
} from '../../../common/selectors/app-settings-selectors';
import {
  getShouldUsePostListMobileSliderArrowColor,
  getShouldUsePostListMobileSliderBorderStyles,
} from '../../../common/selectors/mobile-settings-selectors';
import { getPostListPosts } from '../../../common/selectors/post-selectors';
import { getIsEntityLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getPostCount } from '../../../common/store/post-count/post-count-selector';
import { getPostListLayoutType } from '../../selectors/post-list-layout-type';
import { getPostListWidgetPageSize } from '../../selectors/post-list-widget-page-size';
import NoPostsEmptyState from '../no-posts-empty-state';
import styles from './post-list.scss';

const canSeeMoreButton = () => false;

const PostList: React.FC = () => {
  const [page, setPage] = useState(1);

  const actions = useActions();
  const postListState = usePostListState();
  const featuredOnly = useSelector((state) =>
    getAppSettingsValue({
      state,
      key: `style.booleans.${postListWidgetStyleParams.postListWidgetIsFeatured.key}`,
    }),
  );
  const posts = useSelector(getPostListPosts);
  const pageSize = useSelector(getPostListWidgetPageSize);
  const isPaginationEnabled = useSelector(getPostListPaginationEnabled);
  const postCount = useSelector(getPostCount);
  const prevState = useRef({
    pageSize,
    featuredOnly,
    isPaginationEnabled,
  });
  const layoutOptionsRef = useRef(postListState.layoutOptions);

  useEffect(() => {
    if (
      pageSize !== prevState.current.pageSize ||
      featuredOnly !== prevState.current.featuredOnly ||
      isPaginationEnabled !== prevState.current.isPaginationEnabled
    ) {
      prevState.current = {
        pageSize,
        featuredOnly,
        isPaginationEnabled,
      };
      setPage(1);
      actions.fetchPostListPostsPromisified(1);
    }
  }, [actions, pageSize, featuredOnly, isPaginationEnabled]);

  useEffect(() => {
    if (!isEqual(layoutOptionsRef.current, postListState.layoutOptions)) {
      layoutOptionsRef.current = postListState.layoutOptions;
      actions.fetchPostListPostsPromisified(page);
    }
  }, [postListState.layoutOptions, actions, page]);

  const handlePageClick = (event: { page: number }) => {
    setPage(event.page);
    actions.fetchPostListPostsPromisified(event.page);
  };

  const hasPosts = Boolean(posts.length);

  if (!postListState.isLoading && !hasPosts) {
    return <NoPostsEmptyState />;
  }

  const postListRootClassNames = classNames({
    'mobile-slider-arrow-color': postListState.shouldUsePostListMobileSliderArrowColor,
    'mobile-slider-border-styles': postListState.shouldUsePostListMobileSliderBorderStyles,
  });

  return (
    <div className={postListRootClassNames}>
      <PostListProGallery
        className={
          layoutOptionsRef.current.arrowsPosition === PostListWidgetSliderArrowsPosition.OnGallery
            ? 'arrows-on-gallery'
            : ''
        }
        layoutName={postListState.layoutName}
        layoutType={postListState.layoutType}
        layoutDefaults={POST_LIST_WIDGET_DEFAULTS}
        domId={postListState.componentId}
        allPosts={posts}
        currentPagePosts={posts}
        isLoading={postListState.isLoading}
        showCreatePostAction={false}
        section={SECTION_POST_LIST}
        canSeeMoreButton={canSeeMoreButton}
        layoutOptions={layoutOptionsRef.current}
        hideCoverImage={postListState.hideCoverImage}
      />
      {isPaginationEnabled ? (
        <Pagination
          dataHook="post-list-pagination-container"
          useDescriptionColor={true}
          containerClassName={styles.paginationContainer}
          page={page}
          onChange={handlePageClick}
          pageSize={postListState.pageSize}
          section={SECTION_POST_LIST}
          entityCount={postCount}
          activeClass="blog-post-list-pagination-active"
        />
      ) : null}
    </div>
  );
};

function usePostListState() {
  const deviceType = useDeviceType();

  return useSelector((state, host) => {
    const layoutType = getPostListLayoutType({
      state,
      isMobile: deviceType.isMobile,
    });

    const layoutName = getLayoutName(layoutType) || POST_LIST_WIDGET_DEFAULTS.layoutName;
    const {
      sliderShowArrows,
      sliderArrowsSize,
      sliderAutoSlide,
      sliderPauseTime,
      sliderArrowsPosition,
      sliderArrowsColor,
      sliderLoop,
    } = getUseMobileLayoutSettings(state)
      ? postListWidgetStyleParamsByDevice.mobile
      : postListWidgetStyleParamsByDevice.desktop;

    return {
      posts: getPostListPosts(state),
      layoutType,
      layoutName,
      isLoading: getIsEntityLoading(state, 'postListPosts'),
      componentId: host.id,
      pageSize: getPostListWidgetPageSize(state),
      sliderArrowsPositions: sliderArrowsPosition.options,
      hideCoverImage: !getAppSettingsValue({
        state,
        key: `style.booleans.${
          getUseMobileDisplaySettings(state)
            ? feedWidgetStyleParams['blog-mobile-isCoverImageEnabled'].key
            : feedWidgetStyleParams['blog-isCoverImageEnabled'].key
        }`,
        fallback: true,
      }),
      layoutOptions: {
        showArrows: getAppSettingsValue({
          state,
          key: `style.booleans.${sliderShowArrows.key}`,
          fallback: sliderShowArrows.defaultValue,
        }),
        arrowsSize: getAppSettingsValue({
          state,
          key: `style.numbers.${sliderArrowsSize.key}`,
          fallback: sliderArrowsSize.defaultValue,
        }),
        autoSlide: getAppSettingsValue({
          state,
          key: `style.booleans.${sliderAutoSlide.key}`,
          fallback: sliderAutoSlide.defaultValue,
        }),
        pauseTime: getAppSettingsValue({
          state,
          key: `style.numbers.${sliderPauseTime.key}`,
          fallback: sliderPauseTime.defaultValue,
        }),
        arrowsPosition: getAppSettingsValue({
          state,
          key: `style.numbers.${sliderArrowsPosition.key}`,
          fallback: sliderArrowsPosition.defaultValue,
        }),
        arrowsColor: getAppSettingsValue({
          state,
          key: `style.colors.${sliderArrowsColor.key}`,
          fallback: sliderArrowsColor.defaultValue,
        }),
        loop: getAppSettingsValue({
          state,
          key: `style.booleans.${sliderLoop.key}`,
          fallback: sliderLoop.defaultValue,
        }),
      },
      shouldUsePostListMobileSliderArrowColor: getShouldUsePostListMobileSliderArrowColor(state),
      shouldUsePostListMobileSliderBorderStyles:
        getShouldUsePostListMobileSliderBorderStyles(state),
    };
  });
}

export default PostList;
