import React, { createContext, useContext, useMemo } from 'react';
import { type IStyleParam, type StyleParamType } from '@wix/tpa-settings';
import { useStyles } from '@wix/tpa-settings/react';
import { type TagName } from '@app/common/settings-components/types';

const PostTitleTagContext = createContext<{ tagName: TagName }>({ tagName: 'h2' });

export const PostTitleTagProvider: React.FC<{
  titleFontParam: IStyleParam<StyleParamType.Font>;
  fallbackTagName: TagName;
}> = ({ titleFontParam, fallbackTagName, children }) => {
  const styles = useStyles();
  const font = styles.get(titleFontParam);
  const tagName = (font?.htmlTag || fallbackTagName) as TagName;

  const value = useMemo(() => ({ tagName }), [tagName]);

  return <PostTitleTagContext.Provider value={value}>{children}</PostTitleTagContext.Provider>;
};

export const usePostTitleTag = () => {
  return useContext(PostTitleTagContext);
};
