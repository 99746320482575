import React from 'react';
import classNames from 'classnames';
import {
  isLayoutNameTextOnImage,
  type LayoutName,
  resolveId,
  type Section,
} from '@wix/communities-blog-client-common';
import useDeviceType from '../../hooks/use-device-type';
import { useFeedMetadataSettings } from '../../hooks/use-feed-metadata-settings';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '../../store/post-ratings/post-ratings-selector';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import styles from '../../styles/post-list-item.scss';
import { type NormalizedPost } from '../../types';
import RatingsDisplay, { RatingsDisplayLayout } from '../ratings-display';
import { useSelector } from '../runtime-context';

type Props = {
  post: NormalizedPost;
  type: LayoutName | undefined;
  withoutFooter: boolean;
  section: Section;
};

export const PostListItemProGalleryRatingsDisplay = ({
  post,
  type,
  withoutFooter,
  section,
}: Props) => {
  const { isMobile } = useDeviceType();
  const resolvedPostId = resolveId(post);
  const postAverageRating = useSelector((state) => getPostAverageRating(state, resolvedPostId));
  const postTotalRatings = useSelector((state) => getPostTotalRatings(state, resolvedPostId));
  const feedMetadataSettings = useFeedMetadataSettings({ post });

  return feedMetadataSettings.showPostRating && postTotalRatings ? (
    <RatingsDisplay
      className={classNames(
        styles.rating,
        styles[section],
        type ? styles[type] : undefined,
        alignmentStyles.rating,
        {
          [styles.withoutFooter]: withoutFooter,
          [styles.withTextOnImageStyles]: isLayoutNameTextOnImage(type),
        },
      )}
      rating={postAverageRating}
      count={postTotalRatings}
      layout={isMobile ? RatingsDisplayLayout.with_range : RatingsDisplayLayout.count_only}
    />
  ) : null;
};
