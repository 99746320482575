import { getIsPostPageBundle } from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';

const useFontClassName = () => {
  const contentFontClassName = useSelector((state) =>
    getIsPostPageBundle(state) ? 'blog-post-page-font' : 'blog-post-description-font',
  );

  return {
    titleFontClassName: 'blog-post-title-font',
    contentFontClassName,
    contentFontClassNameWithStyle: 'blog-post-description-style-font',
  } as const;
};

export default useFontClassName;
