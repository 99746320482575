import { type Section } from '@wix/communities-blog-client-common';
import { useSelector } from '../components/runtime-context';
import { getDefaultFontSizeDesktop, getFontSize } from '../selectors/app-settings-selectors';
import { getLayoutType } from '../selectors/layout-selectors';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import useLayoutProps from './use-layout-props';

const usePostFontSize = (unsafeSection?: Section) => {
  const { section } = useLayoutProps({ section: unsafeSection });
  const isMobile = useSelector(getIsMobile);
  const layoutType = useSelector((state) => getLayoutType(state, section, isMobile)!);

  const postTitleFontSize = useSelector((state) =>
    getFontSize(state, layoutType, section!, 'title'),
  );
  const postDescriptionFontSize = useSelector((state) =>
    getFontSize(state, layoutType, section!, 'description'),
  );
  const postMetadataFontSize = useSelector((state) =>
    getFontSize(state, layoutType, section!, 'metadata'),
  );
  const defaultPostTitleFontSize = useSelector((state) =>
    getDefaultFontSizeDesktop(state, layoutType, section!, 'title'),
  );
  const defaultPostDescriptionFontSize = useSelector((state) =>
    getDefaultFontSizeDesktop(state, layoutType, section!, 'description'),
  );

  return {
    postTitleFontSize,
    postDescriptionFontSize,
    postMetadataFontSize,
    defaultPostTitleFontSize,
    defaultPostDescriptionFontSize,
  };
};

export default usePostFontSize;
